import { toast } from 'react-toastify';
import { axiosGet } from '../api/axios';
import { useQuery } from '@tanstack/react-query';

export interface TextSearchResponse {
  count: number;
  data: Experience[];
}

export interface Experience {
  admin_summary: any;
  audio_url: string;
  background_tags?: string[];
  client_id: any;
  client_image_url: any;
  duration: number;
  excerpt?: string;
  id: number;
  is_available: boolean;
  is_favorite: boolean;
  is_listener: boolean;
  listener_role_id: number;
  name: string;
  peer_type: string;
  profile_photo_square_file_url: string;
  rating: any;
  similarity_score: number;
  tag_names: string[];
  title: string;
  transcription: string;
}

export interface SearchFilterOptions {
  supportQuery?: string;
  limit?: number;
  page?: number;
  ageGroups?: string[];
  availableNow?: string | string[];
  backgroundTags?: number[];
  clientId?: number;
  distinctListeners?: boolean;
  ethnicityTags?: number[];
  genderTags?: number[];
  peerType?: string[];
  resourceId?: number;
  createdAt?: Date;
  required_client_tags_client_id?: number;
}

const buildSearchFilterQueryParams = (searchFilterOptions: SearchFilterOptions): any => {
  let params: any = {};

  const mapOptionToParam = {
    query: searchFilterOptions.supportQuery || '',
    limit: searchFilterOptions.limit,
    page: searchFilterOptions.page,
    age_groups: searchFilterOptions.ageGroups?.length ? searchFilterOptions.ageGroups.join(',') : undefined,
    available_now: Array.isArray(searchFilterOptions.availableNow) ? searchFilterOptions.availableNow[0] : undefined,
    background_tags: searchFilterOptions.backgroundTags?.length
      ? searchFilterOptions.backgroundTags.join(',')
      : undefined,
    client_id: searchFilterOptions.clientId ? searchFilterOptions.clientId : undefined,
    distinct_listeners: searchFilterOptions.distinctListeners,
    ethnicity_tags: searchFilterOptions.ethnicityTags?.length ? searchFilterOptions.ethnicityTags.join(',') : undefined,
    gender_tags: searchFilterOptions.genderTags?.length ? searchFilterOptions.genderTags.join(',') : undefined,
    peer_type: searchFilterOptions.peerType?.length ? searchFilterOptions.peerType.join(',') : undefined,
    resource_id: searchFilterOptions.resourceId || undefined,
    required_client_tags_client_id: searchFilterOptions.required_client_tags_client_id || undefined,
  };

  Object.entries(mapOptionToParam).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      params[key] = value;
    }
  });

  return params;
};

export const useRecommendedExperiencesTextSearch = ({
  enabled,
  searchFilterOptions,
}: {
  enabled: boolean;
  searchFilterOptions: SearchFilterOptions;
}) => {
  const params = buildSearchFilterQueryParams(searchFilterOptions);

  return useQuery<TextSearchResponse>(
    ['textSearchExperiences', params],
    () => {
      return axiosGet('/listener_audio/search', { ...params, ignore_search_query: true }, 'v3').then(
        (response: { data: any }) => response.data,
      );
    },
    {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err: any) => {
        // @ts-ignore
        toast.error('oops. Something went wrong. If this persists please contact the dev team.');
      },
    },
  );
};
