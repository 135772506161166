import React, { createContext, useEffect, useRef, useState } from 'react';
import Keycloak from 'keycloak-js';
import { SwitchboardFeatures, useFeatureFlag } from '../hooks/useFeatureFlags';
import { toast } from 'react-toastify';
import urls from '../api/urls';
import { axiosPost } from '../api/axios';
import useStickyState from '../hooks/useStickyState';
import { useMyUser, User } from '../hooks/useUsers';
import { UserRole } from '../containers/navigation-bar/roles';

const http = {
  post: axiosPost,
};

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  setAuthToken: React.Dispatch<string | null>;
  roleType: string | null;
  loginClassic: (emailAddress: string, password: string, trusted: boolean) => Promise<any>;
  passwordResetClassic: (emailAddress: string) => Promise<any>;
  logout: () => void;
  user?: User;
  hasRealmRole: (userRole: UserRole) => boolean;
  isAuthenticated: boolean;
  keycloakFeatureFlag: boolean;
  isLoading: boolean;
  retrieveUserFromQueryParams: () => Promise<any>;
  tokenError: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
};

const TOKEN_EXPIRATION_THRESHOLD = 60; // seconds
const TOKEN_CHECK_INTERVAL = 30000; // milliseconds

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const keycloakFeatureFlag = useFeatureFlag(SwitchboardFeatures.SB_KEYCLOAK_LOGIN);
  const [authToken, setAuthToken] = useStickyState('authorization_token', null);
  const [tokenError, setTokenError] = useState<boolean>(false);
  const { data: user, isFetched: userFetched } = useMyUser(authToken);
  const roleType = user?.administrator_role_id
    ? UserRole.admin
    : user?.client_administrator_role_id
      ? UserRole.client_admin
      : null;

  const isAuthenticated = Boolean(user) && !tokenError;

  const isLoading = (authToken && !userFetched) || !keycloak;

  // "Classic" login means non-keycloak legacy auth
  const loginClassic = async (emailAddress: string, password: string, trusted: boolean) => {
    const data = {
      email_address: emailAddress,
      password,
      trusted,
    };

    return http
      .post(urls.login, data)
      .then((results: any) => {
        const { access_role, authorization_token } = results.data;
        setAuthToken(authorization_token);
        return results;
      })
      .catch((error: any) => {
        if (error.response) {
          const { response } = error;
          toast.error(response.data.description);
        }
      });
  };

  const passwordResetClassic = async (emailAddress: string) => {
    const url = `${urls.users}request_password_reset?email_address=${emailAddress.replace('+', '%2B')}&from_location=switchboard`;
    return http
      .post(url, {})
      .then((results: any) => {
        toast.success(
          'Thank you. We have sent you an email with instructions to retrieve your password. If you have not received our message, check that you have entered the correct email address',
        );
      })
      .catch((error: any) => {
        toast.error(
          'Something went wrong. Please make sure your email address is correct, and that you have completed onboarding. If this problem persists please contact support.',
        );
      });
  };

  const retrieveUserFromQueryParams = async () => {
    // this is a hack to avoid the forgot password page
    if (window.location.pathname.includes('/reset-password')) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const user_id = queryParams.get('user_id');

    if (!token || !user_id) {
      return;
    }

    const url = `${urls.user}/${user_id}/authenticate_with_token`;
    const payload = {
      token,
      user_id,
    };
    return http
      .post(url, payload)
      .then((results: any) => {
        console.log(results);
        setAuthToken(results.data.authorization_token);
        return results;
      })
      .catch((error: any) => {
        if (error.response) {
          if (tokenError) {
            return;
          }
          console.log('trying to set an error here');
          // seems like we need to figure out if this is invalid or expired
          setTokenError(true);
        }
      });
  };

  const logout = async () => {
    /*
      if keycloak.logout() is called before axiosPost is invoked,
      the browser redirects before deauth can fire, leaving the session valid
      in safari private mode, so we await before we do anything else
    */
    await http.post(`/users/deauthenticate`, {});
    setTokenError(false);
    setAuthToken(null);
    localStorage.clear();
    if (keycloakFeatureFlag.enabled && keycloak) {
      keycloak?.logout();
    } else {
      // this isn't necessary when we are fully on keycloak as IDP
      toast.success('User Logged Out Successfully');
      window.location.href = '/';
    }
  };

  const hasRealmRole = (userRole: UserRole): boolean => {
    return Boolean(keycloak?.hasRealmRole(userRole));
  };

  const checkAndRefreshToken = () => {
    if (keycloak?.authenticated && keycloak.isTokenExpired(TOKEN_EXPIRATION_THRESHOLD)) {
      keycloak
        .updateToken(TOKEN_EXPIRATION_THRESHOLD)
        .then((refreshed) => {
          if (refreshed) {
            setAuthToken(keycloak?.token ?? null);
          }
        })
        .catch(() => {
          setAuthToken(null);
        });
    }
  };

  const initKeycloak = async () => {
    const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

    keycloakInstance.onAuthSuccess = () => {
      setAuthToken(keycloakInstance.token ?? null);
    };

    keycloakInstance.onTokenExpired = () => {
      keycloakInstance
        .updateToken()
        .then((refreshed) => {
          if (refreshed) {
            setAuthToken(keycloakInstance.token ?? null);
          }
        })
        .catch(() => {
          setAuthToken(null);
        });
    };

    keycloakInstance
      .init({
        onLoad: 'check-sso',
      })
      .then((authenticated: boolean) => {})
      .catch((error) => {
        console.error('Keycloak initialization failed:', error);
      })
      .finally(() => {
        setKeycloak(keycloakInstance);
      });
  };

  useEffect(() => {
    if (isFirstRun.current) return;

    isFirstRun.current = true;
    initKeycloak();

    const tokenCheckInterval = setInterval(checkAndRefreshToken, TOKEN_CHECK_INTERVAL);
    return () => clearInterval(tokenCheckInterval);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        keycloak: keycloak,
        authToken,
        setAuthToken,
        roleType,
        loginClassic,
        logout,
        passwordResetClassic,
        user,
        hasRealmRole,
        isAuthenticated,
        keycloakFeatureFlag: keycloakFeatureFlag.enabled,
        isLoading,
        retrieveUserFromQueryParams,
        tokenError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
