import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from 'react';
import { DropDown, Pagination, SectionLabel, Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './common/listener-review-constants';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AudioPlayer from '../../components/common/audio-player-slider';
import Modal from '../../components/common/modal';
import { fetchCommsListRequest } from '../templates/redux-templates/actions';
import { fetchTagsRequest } from '../clients/clients-redux/actions';
import {
  fetchAudioListSuccess,
  postAudioRequest,
  updateAudioRequest,
  fetchListenersAudioListRequest,
  fetchListenersAudioListCountRequest,
} from '../peer-profiles/listeners-redux/actions';
import SaveButton from '../clients/switch-bar-button';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import Searchbar from '../../components/common/searchbar';
import { audioGrades } from './common/listener-constants';
import { toast } from 'react-toastify';
import { Tag } from '../../hooks/useListenerTags';
import { Dialog } from '@kindlyhuman/component-library';

const GradeDropDownOptions = [
  { value: 'rejected', name: 'Rejected' },
  { value: 'approved', name: 'Approved' },
];

const messageGroups = {
  rejected: 'listener_message_rejected',
  approved: 'approved',
  ungraded: 'ungraded',
};

type props = {
  AudioList: any;
  tags: any;
  comms: any;
  pending: boolean;
  AudioCount: any;
};

type gradeOptionsProps = {
  seletedId: any;
  comms: any;
  tagList: any;
  setIsDirty: (isDirty: boolean) => void;
  setTopicsDirty: (isDirty: boolean) => void;
  setRedirect: (path: string) => void;
  listenerId: number;
  filters: any;
};

interface refProps {
  submit(): void;
  handleSendRejectMessage(): void;
}

interface promptProps {
  modalVisible: boolean;
  isDirty: boolean;
  isTopicsDirty: boolean;
  promptId?: number;
}

const defaultErrorMessages = {
  message: '',
  description: '',
};

type FilterProps = {
  count: number | undefined;
  fetchData: (data: any) => void;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
    grade: audioGrades.Ungraded,
    hide_incomplete_peers: true,
  });

  useEffect(() => {
    fetchData(filters);
  }, [filters, fetchData]);

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setfilters((prev) => {
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) =>
            setfilters((filter) => {
              return { ...filter, page: pages + 1 };
            })
          }
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(audioGrades).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (audioGrades[item] === filters.grade
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setfilters((filter: any) => {
                    return {
                      ...filter,
                      //@ts-ignore
                      grade: audioGrades[item],
                      page: 1,
                    };
                  });
                }}
              >
                {/* @ts-ignore */}
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const GradeOptions = forwardRef<refProps, gradeOptionsProps>(
  ({ seletedId: audio, comms, tagList, setIsDirty, setTopicsDirty, setRedirect, listenerId, filters }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [errors, setErrors] = useState(defaultErrorMessages);
    const [gradeSelection, setgradeSelection] = useState<any>({
      grade: 'rejected',
      sytemMessageGroup: 'listener_message_rejected',
      messageId: 'none',
      message: '',
      excerpt: '',
      topicsUpdated: false,
      gradeUpdated: false,
    });
    const [groupDownOptions] = useState({
      listener_message_rejected: [],
      listener_message_poor: [],
      approved: [],
    });
    const [addTagspopUp, setaddTagspopUp] = useState(false);
    const [tagsList, setTags] = useState<any>([]);

    useEffect(() => {
      const groupKey = ['listener_message_rejected', 'listener_message_poor'];
      comms.forEach((item: any) => {
        if (groupKey.includes(item.group?.key)) {
          //@ts-ignore
          groupDownOptions[item.group.key].push(item);
        }
      });
    }, [comms, groupDownOptions]);

    const submit = () => {
      if (gradeSelection.topicsUpdated && verifyMessageErrors(false, 'submit')) {
        setErrors(defaultErrorMessages);
        const selectedIds = tagsList.filter((item: any) => item.status).map((item: any) => item.id);
        const MyStory = tagsList.find((item: any) => item.name === 'My Story' && item.is_required);

        const input = {
          id: listenerId,
          audioId: audio.id,
          type: 'messages',
          filters,
          payload: {
            topic_tag_ids: audio.title === 'My Story' ? [...selectedIds, MyStory.id] : selectedIds,
            excerpt: gradeSelection.excerpt != audio.excerpt ? gradeSelection.excerpt.trim() : undefined,
            admin_summary:
              gradeSelection.admin_summary != audio.admin_summary ? gradeSelection.admin_summary.trim() : undefined,
          },
        };
        dispatch(updateAudioRequest(input));
      }
    };

    useImperativeHandle(ref, () => ({
      submit,
      handleSendRejectMessage,
    }));

    useEffect(() => {
      if (tagList?.length > 0 && audio.topic_tags !== undefined) {
        const selectedTags: any[] =
          audio.topic_tags?.length > 0 ? audio.topic_tags.map((item: any) => item.tag_id) : [];

        const tagsArr: any[] = tagList.map((item: any) => ({
          ...item,
          status: !!selectedTags?.includes(item.id),
        }));
        setTags(tagsArr);
      }
    }, [tagList, audio]);

    useEffect(() => {
      audio &&
        setgradeSelection({
          grade: audio?.message_quality === 3 ? 'approved' : audio?.message_quality === 7 ? 'ungraded' : 'rejected',
          sytemMessageGroup: 'listener_message_rejected',
          messageId: 'none',
          message: '',
          excerpt: audio?.excerpt || '',
          admin_summary: audio?.admin_summary || '',
          ai_summary: audio?.ai_summary || '',
          topicsUpdated: false,
          gradeUpdated: false,
        });
    }, [audio]);

    const setToggle = (status: any, id: any) => {
      if (!gradeSelection.topicsUpdated) {
        setgradeSelection((prev: any) => ({ ...prev, topicsUpdated: true }));
      }
      const updatedTags = tagsList.map((item: any) => {
        if (item.id === id) {
          return { ...item, status };
        } else {
          return item;
        }
      });
      setTags(updatedTags);
      handleIsDirty(true);
    };

    const verifyMessageErrors = (sendMessage: boolean, type: string) => {
      if (type === 'message') {
        if ((!sendMessage && gradeSelection.message?.trim()?.length > 0) || sendMessage) {
          setErrors((prev: any) => ({
            ...prev,
            message: '',
          }));
          return true;
        } else {
          setErrors((prev: any) => ({
            ...prev,
            message: 'Please Enter a message to proceed',
          }));
          return false;
        }
      } else {
        if (tagsList?.filter((item: any) => item.status && item.name !== 'My Story').length <= 0) {
          toast.error('Experiences must have at least 1 topic tag');
          return false;
        } else if (gradeSelection.excerpt?.trim()?.length > 0) {
          setErrors((prev: any) => ({
            ...prev,
            description: '',
          }));
          return true;
        } else {
          setErrors((prev: any) => ({
            ...prev,
            description: 'Please Enter a description to proceed',
          }));
          return false;
        }
      }
    };

    const handleSendRejectMessage = () => {
      const sendMessage = !!(gradeSelection.messageId === 'none' || gradeSelection.grade === 'approved');
      if (gradeSelection.gradeUpdated && verifyMessageErrors(sendMessage, 'message')) {
        const input = {
          id: listenerId,
          audioId: audio.id,
          type: 'messages',
          filters,
          payload: {
            system_message_id: sendMessage ? undefined : +gradeSelection.messageId,
            message: sendMessage ? undefined : gradeSelection.message.trim(),
            message_quality: gradeSelection.grade,
          },
        };
        dispatch(postAudioRequest(input));
      }
    };

    const handleIsDirty = (isTopics = false) => {
      const type = isTopics ? 'topicsUpdated' : 'gradeUpdated';
      if (!gradeSelection[type]) {
        setgradeSelection((prev: any) => ({
          ...prev,
          [type]: true,
        }));
        if (isTopics) {
          setTopicsDirty(true);
          !gradeSelection.topicsUpdated && setgradeSelection((prev: any) => ({ ...prev, topicsUpdated: true }));
        }
      }
      setIsDirty(true);
    };

    return (
      <>
        <RouteLeavingGuard
          when={gradeSelection.gradeUpdated || gradeSelection.topicsUpdated}
          navigate={(path: string) => {
            history.push(path);
          }}
          shouldBlockNavigation={(location: any) => {
            if (location.pathname !== ROUTE_PATH.LISTENER_AUDIO_APPROVAL) {
              return true;
            }
            return false;
          }}
          titleText={'Alert'}
          contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
          cancelButtonText="Cancel"
          confirmSaveButtonText={'Save Changes'}
          confirmButtonText={'Disregard Changes'}
          handleContinueSaveChange={(path: string) => {
            const sendMessage = !!(gradeSelection.messageId === 'none' || gradeSelection.grade === 'approved');
            if (verifyMessageErrors(false, 'submit') && verifyMessageErrors(sendMessage, 'message')) {
              setRedirect(path);
              setgradeSelection((prev: any) => ({
                ...prev,
                topicsUpdated: false,
                gradeUpdated: false,
              }));
              submit();
              handleSendRejectMessage();
            }
          }}
        />
        <Dialog open={addTagspopUp} onClose={() => setaddTagspopUp(false)}>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                    Add Listener Tags
                  </h3>

                  <div className="w-full pb-4 flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                    {tagsList?.length > 0 &&
                      tagsList
                        .filter((item: any) => !item.is_required)
                        .sort((tag1: Tag, tag2: Tag) => tag1.name.localeCompare(tag2.name))
                        .map((item: any, index: number) => {
                          return (
                            <button
                              className={` ${
                                item.status ? ' bg-blue-primary  text-white' : ' bg-gray-background-light '
                              }  rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                              onClick={() => {
                                setToggle(!item.status, item.id);
                              }}
                            >
                              <div className="flex px-3 py-2">
                                <div className="grow  pr-2">{item.name}</div>
                              </div>
                            </button>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center">
              <button
                type="button"
                className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
                onClick={() => {
                  setaddTagspopUp(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Dialog>
        <div className="border-2 border-gray-200 rounded-3xl px-5 h-auto">
          <span className="w-5/6 inline-flex">
            <span className="font-bold text-left text-gray-dark py-4 text-xl">
              <p className={`font-bold text-left`}>{audio.title}</p>
              <p className={`font-bold text-left truncate `}>by {audio.listener_role?.user?.first_name || ''}</p>
              <p className={`font-bold text-left`}>
                aka{' '}
                {audio.listener_role?.user?.display_name
                  ? audio.listener_role?.user?.display_name
                  : audio.listener_role?.user?.email_address}
              </p>
            </span>
          </span>
          <p className="w-1/6 h-max inline-flex font-bold text-gray-dark py-4 text-xl">#{audio.id}</p>
          <AudioPlayer audioLength={audio.duration} audioUrl={audio.file_url} />
          <div className="flex justify-start mt-5">
            <label className="mr-2">Grade</label>
            <div className="w-48">
              {/* @ts-ignore */}
              <DropDown
                Label={''}
                value={gradeSelection.grade}
                setValue={(value) => {
                  value !== 'ungraded' && handleIsDirty();
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    grade: value,
                    //@ts-ignore
                    sytemMessageGroup: messageGroups[value],
                    gradeUpdated: value === 'ungraded' ? false : true,
                  }));
                }}
              >
                {audio?.message_quality === 7 && <option value={'ungraded'}>Ungraded</option>}
                {GradeDropDownOptions.length > 0 &&
                  GradeDropDownOptions.map((item: any) => <option value={item.value}>{item.name}</option>)}
              </DropDown>
            </div>
          </div>
          {gradeSelection.grade === 'rejected' && (
            <div className="flex justify-start w-full">
              <label className="mr-2">Message to Listener</label>
              <DropDown
                Label={''}
                value={gradeSelection.messageId}
                setValue={(value) => {
                  handleIsDirty();
                  //@ts-ignore
                  const comm = groupDownOptions[gradeSelection.sytemMessageGroup].find(
                    (item: any) => item.id === +value,
                  );
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    messageId: value,
                    message: comm ? comm.message : prev.message,
                  }));
                }}
              >
                <option value={'none'}>Do NOT Message Listener</option>
                {/* @ts-ignore */}
                {groupDownOptions[gradeSelection.sytemMessageGroup]?.length > 0 &&
                  // @ts-ignore
                  groupDownOptions[gradeSelection.sytemMessageGroup]?.map((item: any) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </DropDown>
            </div>
          )}
          {gradeSelection.messageId === 'rejected' && (
            <div className="w-full  my-2">
              <p className="mb-2"> Message </p>
              <textarea
                className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                value={gradeSelection?.message}
                onChange={(evt) => {
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    message: evt.target.value,
                  }));
                }}
              />
              {errors.message?.length > 0 && <p className="text-red">{errors.message}</p>}
            </div>
          )}
          <button
            className={
              ' py-2 px-3 rounded-full mt-4 ' +
              (gradeSelection.gradeUpdated
                ? 'bg-blue-primary  text-white'
                : 'bg-gray-background-light text-gray-dark cursor-not-allowed')
            }
            disabled={!gradeSelection.gradeUpdated || gradeSelection.grade === '7'}
            onClick={handleSendRejectMessage}
          >
            Submit Grade
          </button>
          <div>
            <SectionLabel Label={'Description'} />
            <div className="pt-4">
              <textarea
                className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                value={gradeSelection?.excerpt}
                onChange={(evt) => {
                  handleIsDirty(true);
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    excerpt: evt.target.value,
                  }));
                }}
              />
              {errors.description?.length > 0 && <p className="text-red">{errors.description}</p>}
            </div>
          </div>
          <div>
            <SectionLabel Label="Transcription" />
            <div className="pt-2 pb-6">{audio.transcription}</div>
          </div>
          <div>
            <SectionLabel Label={'Admin Summary'} />
            <div className="pt-4">
              <textarea
                className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                value={gradeSelection?.admin_summary}
                onChange={(evt) => {
                  handleIsDirty(true);
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    admin_summary: evt.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <SectionLabel Label="AI Summary" />
            <div className="pt-2 pb-6">
              {audio.ai_summary ? audio.ai_summary : 'No AI summary generated at this time.'}
            </div>
          </div>
          <div className="flex justify-between">
            <p className="py-2 font-bold text-gray-dark text-left">Topic Tags</p>
            <button
              className="py-2 font-bold text-right text-blue-primary "
              onClick={() => {
                setaddTagspopUp(true);
              }}
            >
              + Add
            </button>
          </div>
          <hr className="bg-gray-dark mb-2"></hr>
          <div className="w-full flex-wrap pb-4 space-x-2 space-y-2 text-sm -ml-2 ">
            {tagsList?.length > 0 &&
              tagsList

                .filter((item: any) => item.status && !item.is_required)
                .map((item: any, index: number) => {
                  return (
                    <button
                      className={`bg-blue-primary text-white rounded-md text-sm font-bold ${
                        index === 0 ? 'ml-2 mt-2' : ''
                      }`}
                    >
                      <div className="flex px-3 py-2">
                        <div className="grow  pr-2">{item.name}</div>
                        <div
                          className="grow-0 "
                          onClick={() => {
                            setToggle(false, item.id);
                          }}
                        >
                          x
                        </div>
                      </div>
                    </button>
                  );
                })}
          </div>
          {audio?.opensearch_tag_ids && (
            <>
              <hr className="mt-4" />
              <div className="flex flex-col">
                <p className="py-2 font-bold text-gray-dark text-left">AI Recommended Tags</p>
                <div className="flex gap-4 py-4">
                  {audio?.opensearch_tag_ids?.map((id: number) => {
                    return (
                      <div className="bg-gray-600 text-white rounded-md text-sm font-bold px-3 py-2">
                        {tagList.find((tag: Tag) => tag.id === id)?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

const Audio: React.FunctionComponent<props> = (props): JSX.Element => {
  const audioRef: any = useRef();
  const dispatch = useDispatch();
  const [seletedId, setseletedId] = useState<any>();

  const [savedPath, setSavedPath] = useState('');
  const [prompt, setPrompt] = useState<promptProps>({
    modalVisible: false,
    isDirty: false,
    isTopicsDirty: false,
  });
  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 25,
    search: '',
    grade: audioGrades.Ungraded,
  });
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCommsListRequest({}));
    dispatch(fetchTagsRequest({ tag_type: 'topic', include_hidden: true }));
    // dispatch(fetchListenersAudioListRequest({ limit: 25, page: 1 }));

    return () => {
      dispatch(fetchAudioListSuccess({ AudioList: undefined }));
    };
  }, []); // eslint-disable-line

  const fetchData = useCallback(
    (filter: any) => {
      setFilters(filter);
      setseletedId(null);
      const { grade, ...rest } = filter;
      const params = filter.grade === audioGrades.All ? rest : filter;
      dispatch(fetchListenersAudioListRequest(params));
      dispatch(fetchListenersAudioListCountRequest(params));
    },
    [dispatch],
  );

  useEffect(() => {
    if (props.AudioList) {
      if (!seletedId && props.AudioList?.length > 0) {
        const ungraded = props.AudioList.find((item: any) => item.message_quality === 7);
        setseletedId(ungraded ? ungraded : props.AudioList[0]);
      } else {
        const audio = props.AudioList.find((item: any) => item.id === seletedId.id);
        setseletedId(audio);
      }
      if (savedPath?.length > 0) {
        history.push(savedPath);
        setSavedPath('');
      }
      setPrompt({
        modalVisible: false,
        isDirty: false,
        isTopicsDirty: false,
      });
    }
  }, [props.AudioList]); // eslint-disable-line

  const handleContinueSave = () => {
    audioRef.current.handleSendRejectMessage();
    audioRef.current.submit();
    setPrompt((prev: promptProps) => ({ ...prev, modalVisible: false }));
  };
  return (
    <div className="px-">
      {props.pending && <Spinner />}
      <Header
        heading={
          <div className="w-full flex">
            <p>Experiences</p>
          </div>
        }
      />

      <SwitchBar
        heading={HeaderOptions}
        position={0}
        optionalButton={
          <div className={`w-1/12  `}>
            <SaveButton
              disable={!prompt.isTopicsDirty}
              onSave={() => {
                audioRef.current.submit();
              }}
            />
          </div>
        }
      />
      <Filters fetchData={fetchData} count={props.AudioCount?.count || 0} />
      <AlertBox
        visible={prompt.modalVisible}
        handlePrompt={() => {
          setseletedId(prompt.promptId);
          setPrompt({
            isDirty: false,
            isTopicsDirty: false,
            modalVisible: false,
            promptId: undefined,
          });
        }}
        handleContinueSave={handleContinueSave}
        closeModal={() => {
          setPrompt((prev: promptProps) => ({
            ...prev,
            modalVisible: false,
          }));
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
        confirmButtonText={'Discard Changes'}
        confirmSaveButtonText={'Save Changes'}
        cancelButtonText={'Cancel'}
      />
      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="px-7 flex space-x-4 py-5">
          <div className="w-1/2  ">
            <div className="flex justify-between border-b border-gray-500 pb-2 cursor-pointer">
              <p className="font-bold text-gray-dark text-left">Experiences</p>{' '}
            </div>
            <div className="max-window-height-audio-reviews overflow-y-scroll">
              {props.AudioList?.length > 0 &&
                props.AudioList.map((item: any) => (
                  <div
                    className={` ${
                      seletedId?.id === item.id
                        ? 'bg-blue-primary  text-white'
                        : 'bg-gray-background-light text-gray-dark'
                    }   font-medium text-lg flex justify-between px-7 h-28 items-center rounded-2xl my-3 cursor-pointer`}
                    onClick={() => {
                      prompt.isDirty
                        ? setPrompt((prev: promptProps) => ({
                            ...prev,
                            modalVisible: true,
                            promptId: item,
                          }))
                        : setseletedId(item);
                    }}
                  >
                    <span>
                      <div className="truncate">
                        <p className={`font-bold   text-left`}>{item.title}</p>
                        <p className={`font-bold   text-left truncate `}>
                          <span className={`${seletedId?.id === item.id ? '  text-white' : 'text-blue-primary'}`}>
                            by {item.listener_role?.user?.first_name || ''}
                          </span>
                        </p>
                        <p className={`font-bold   text-left`}>
                          aka{' '}
                          {item.listener_role?.user?.display_name
                            ? item.listener_role?.user?.display_name
                            : item.listener_role?.user?.email_address}
                        </p>
                      </div>
                    </span>
                    <span>
                      <p>#{item.id}</p>
                      <button
                        className={
                          'border   rounded-md px-4 text-base py-1 font-bold ' +
                          (seletedId?.id === item.id
                            ? ' border-white '
                            : item?.message_quality === 7
                              ? ' border-gray-dark '
                              : '') +
                          (item?.message_quality === 3
                            ? 'bg-green text-white'
                            : item?.message_quality === 7
                              ? ' bg-transparent '
                              : ' bg-red text-white')
                        }
                      >
                        {item?.message_quality === 3
                          ? 'Approved'
                          : item?.message_quality === 7
                            ? 'Ungraded'
                            : 'Rejected'}
                      </button>
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-1/2 px-3">
            {props.AudioList?.length > 0 && seletedId && props.comms?.length > 0 && props.tags?.length > 0 && (
              <GradeOptions
                ref={audioRef}
                seletedId={seletedId}
                comms={props.comms}
                key={seletedId || 0}
                tagList={props.tags}
                setIsDirty={(isDirty: boolean) => setPrompt((prev: promptProps) => ({ ...prev, isDirty }))}
                listenerId={seletedId?.listener_role?.id || 0}
                setTopicsDirty={(isTopicsDirty: boolean) =>
                  setPrompt((prev: promptProps) => ({
                    ...prev,
                    isTopicsDirty,
                  }))
                }
                filters={filters}
                setRedirect={setSavedPath}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    AudioList: state.Listeners?.ListenersAudioList?.data || null,
    AudioCount: state.Listeners?.ListenersAudioListCount?.data || null,
    tags: state.Client.tags?.data || null,
    comms: state.Comms.CommsList?.data || null,
    pending:
      state.Comms.pending ||
      state.Listeners.pending ||
      state.Client.pending ||
      state.Listeners.reviewAudioPending ||
      false,
  };
};

export default connect(mapStateToProps)(Audio);
