import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  postEligibilityRequest,
  postValidateEligibiltyRequest,
  postValidateEligibiltySuccess,
  updateEligibiltyRequest,
} from '../../containers/clients/eligibility-redux/actions';
import moment from 'moment-timezone';
import Spinner from '../common/spinner';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { DropDown } from '../common';
import Modal from '../common/modal';
import { UserRole } from '../../containers/navigation-bar/roles';
import useAuth from '../../hooks/useAuth';

type props = {
  clientId?: any;
  handleModel: (isShow: boolean) => void;
  pending: any;
  validation: any;
  handleUpload: () => void;
  eligibilityId: number;
  handleValidationViewModel: (data: any) => void;
  uploadLiveTime: any;
  display: boolean;
  eligibilityActiveAndScheduleList: any;
};

const EligibilityFileUpload: React.FunctionComponent<props> = (props): JSX.Element => {
  const { clientId } = props;
  const fileInput: any = React.createRef();
  const dispatch = useDispatch();
  const [csvFile, setCsvPreview] = useState<any>();
  const [fileName, setfileName] = useState('Upload file');
  const [scheduledDate, setscheduledDate] = useState('');
  const [timeOftheday, settimeOftheday] = useState<any>();
  const [, seterror] = useState(false);
  // const clientId = localStorage.getItem('clientid');
  const startdate = moment(new Date()).startOf('month');
  const [liveDates, setLiveDates] = useState<any>([]);
  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  const [eligibilityId, setEligibilityId] = useState<number>(props.eligibilityId);
  const { roleType: userType } = useAuth();

  const validation = props.validation || null;
  const hadleCsvInput = (e: any) => {
    fileInput.current.click();
  };

  const onFileChange = async (event: any) => {
    try {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          setCsvPreview(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
        setfileName(event.target.files[0].name);
      }
    } catch (error) {
      console.error('err--', error);
    }
  };

  const handleTimeValueChange = (value: any) => {
    if (value) settimeOftheday(value);
    else settimeOftheday(moment());
  };
  useEffect(() => {
    if (timeOftheday) {
      seterror(false);
    }
  }, [timeOftheday]);
  const handleValidate = () => {
    const linkInput = {
      clientId,
    };
    const payload = {
      eligibility_file: csvFile,
    };
    dispatch(postValidateEligibiltyRequest({ linkInput, payload }));
  };

  const handleUpload = () => {
    let linkInput: any = {
      clientId,
    };

    const useDate = moment(scheduledDate);
    const time = timeOftheday.format('HH:mm:00');
    const timeAndDate = moment(scheduledDate + ' ' + time);

    const timeOfDayString = (
      timeAndDate ? timeAndDate.utc() : moment.tz('US/Central').set({ hour: 4, minute: 0 }).tz('UTC')
    ).format('HH:mm:00');
    const scheduledAt = `${useDate.utc().format('YYYY-MM-DD')} ${timeOfDayString}`;
    const payload: any = {
      scheduled_at: scheduledAt,
      eligibility_file: csvFile,
    };
    const findByDate = props.eligibilityActiveAndScheduleList?.find(
      (item: any) => item.billing_cycle === scheduledDate,
    );
    if (findByDate && !eligibilityId) {
      setIsDisplay(true);
      setEligibilityId(findByDate?.id);
      return;
    }
    if (eligibilityId) {
      linkInput['eligibilityId'] = eligibilityId;
      dispatch(updateEligibiltyRequest({ linkInput, payload }));
    } else {
      dispatch(postEligibilityRequest({ linkInput, payload }));
    }
  };

  useEffect(() => {
    if (props.validation) {
      dispatch(postValidateEligibiltySuccess({}));
    }
    let monthArray = [];
    let month: any = startdate.month();
    const lastMonth: number = month + 11;
    for (month; month < lastMonth; month++) {
      monthArray.push(startdate.add(1, 'M').format('YYYY-MM-DD'));
    }
    setLiveDates(monthArray);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (props.uploadLiveTime && liveDates) {
      const date = new Date(props.uploadLiveTime?.substring(0, 19));
      var local = moment(props.uploadLiveTime).local();
      settimeOftheday(moment(local, 'HH:mm'));
      if (props.eligibilityId) {
        setscheduledDate(moment(date).format('YYYY-MM-DD'));
      }
    }
  }, [props.uploadLiveTime, liveDates]); //eslint-disable-line

  useEffect(() => {
    if (scheduledDate === '' && liveDates.length) {
      setscheduledDate(liveDates[0]);
    }
  }, [liveDates, scheduledDate]);

  useEffect(() => {
    setIsDisplay(props.display);
  }, [props.display]);

  return (
    <div>
      {props.pending && <Spinner />}
      <div
        className={
          'justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none' +
          (isDisplay && ' hidden ')
        }
      >
        <div className="relative w-auto my-6 mx-auto max-w-5xl min-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between">
              <div className="text-2xl px-10 pt-10 blue-primary">Upload Eligibility File</div>
            </div>
            <div className="relative px-10 py-5 flex-auto">
              <p className="leading-relaxed h-full mb-4 text-gray-dark text-base font-normal">
                Set the day {userType === UserRole.admin && 'and time'} for the new members to be activated
              </p>
            </div>
            <table className="table-fixed table-row-format relative border-collapse mx-10">
              <thead className="bg-white  h-full ">
                <tr className=" text-left px-7">
                  <th className="px-2 py-2  w-1/2 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Live Date
                  </th>
                  <th className="px-2 py-2  w-1/2 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    {userType === UserRole.admin ? 'Live Time' : 'File'}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-2"></tr>
                <tr
                  role="button"
                  className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                >
                  <td className="w-1/2 text-gray-dark text-bold font-bold border-bottom pr-7">
                    <div className="w-full">
                      <DropDown Label={''} value={scheduledDate} setValue={setscheduledDate}>
                        {liveDates.length && liveDates.map((date: any) => <option value={date}>{date}</option>)}
                      </DropDown>
                    </div>
                  </td>
                  {userType === UserRole.admin ? (
                    <td className="w-1/2 text-gray-dark text-bold font-bold border-bottom">
                      <div className="w-full">
                        <TimePicker
                          use12Hours
                          showSecond={false}
                          className={'w-full'}
                          onChange={handleTimeValueChange}
                          value={timeOftheday ? timeOftheday : moment().local()}
                        />
                      </div>
                    </td>
                  ) : (
                    <td className="w-1/2 text-gray-dark text-bold font-bold border-bottom pr-1">
                      <div
                        className="w-full py-2 px-3 flex items-center rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                        onClick={hadleCsvInput}
                        role="button"
                      >
                        {fileName}
                      </div>
                      <input
                        ref={fileInput}
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={onFileChange}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            {userType === UserRole.admin && (
              <table className="table-fixed table-row-format relative border-collapse mx-10">
                <thead className="bg-white  h-full ">
                  <tr className=" text-left px-7">
                    <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                      File
                    </th>
                    {userType === UserRole.admin && (
                      <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal"></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr className="h-2"></tr>

                  <tr
                    role="button"
                    className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                  >
                    <td className="w-10/12 text-gray-dark text-bold font-bold border-bottom pr-1">
                      <div
                        className="w-full py-2 px-3 flex items-center rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                        onClick={hadleCsvInput}
                        role="button"
                      >
                        {fileName}
                      </div>
                      <input
                        ref={fileInput}
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={onFileChange}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                    </td>

                    <td className="w-2/12 text-gray-dark text-bold font-bold border-bottom">
                      <div className="flex items-center justify-center p-1">
                        {csvFile && (
                          <button
                            onClick={handleValidate}
                            className={
                              ' text-sm  px-3 py-2 text-white text-center justify-center rounded-md flex items-center   w-24 ' +
                              (fileName.length > 1 ? 'btn-darkblue ' : 'bg-gray-light')
                            }
                          >
                            VALIDATE
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {(!props.eligibilityId || csvFile) && userType === UserRole.admin && (
              <table className="table-fixed table-row-format relative border-collapse mx-10 my-5">
                <thead className="bg-white  h-full ">
                  <tr className=" text-left  w-full px-7">
                    <th className="px-2 py-1 w-2/12 sticky top-0  bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                      Members
                    </th>

                    <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                      New
                    </th>
                    <th className="px-2 py-1  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                      Existing
                    </th>
                    <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                      Removed
                    </th>
                    <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                      Invalid
                    </th>

                    <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className="h-2"></tr> */}
                  {new Array(1).fill(1).map((item: any, index: any) => {
                    return (
                      <tr className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white">
                        <td className="py-1 w-2/12 text-gray-dark text-bold font-bold border-bottom text-base">
                          {validation?.record_count || 0}
                        </td>

                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                              {validation?.members_to_add?.length || 0}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                            {validation?.existing_members?.length || 0}
                          </p>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                            {validation?.members_to_remove?.length || 0}
                          </p>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                            {validation?.invalid_records?.length || 0}
                          </p>
                        </td>

                        <td className="py-1 w-2/12 border-bottom">
                          {/* <button className="rounded-full  py-1 text-sm  text-white w-20 text-center stacked-bar-red-primary" >FIX </button> */}
                          {validation && (
                            <div
                              className="rounded-full  py-1 text-sm  text-white w-20 text-center stacked-bar-green-primary"
                              // onClick={() => props.handleModel(true)}
                            >
                              PASS
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {validation && (!props.eligibilityId || csvFile) && userType === UserRole.admin && (
              <>
                <div className="flex items-center justify-center p-3">
                  <button
                    className="text-sm  px-3 py-2 text-white text-center justify-center rounded-md flex items-center  bg-gray-light w-24"
                    onClick={() => props.handleValidationViewModel(validation)}
                    disabled={validation ? false : true}
                  >
                    VIEW
                  </button>
                </div>
              </>
            )}
            {(props.eligibilityId && !csvFile) ||
            (validation && csvFile) ||
            (userType === UserRole.client_admin && csvFile) ? (
              <div className="flex items-center justify-center pt-6 ">
                <button className="px-7 py-2 text-sm btn-darkblue" onClick={handleUpload}>
                  UPLOAD
                </button>
              </div>
            ) : null}

            <div className="flex items-center justify-center p-6">
              <button
                className="px-7 py-2 text-sm bg-gray-light text-white text-cente rounded-full"
                type="button"
                onClick={() => props.handleModel(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isModel={isDisplay}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Alert
                  </h3>
                  <div className="mt-2 flex">
                    <p className="text-sm text-gray-500 flex items-center">
                      Eligibility data for billing cycle {scheduledDate} exists, Do you want to replace the file?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleUpload}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Replace
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setIsDisplay(false);
                  setEligibilityId(0);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      ></Modal>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pending: state.eligibility.pending || null,
    validation: state.eligibility.eligibiltyValidation?.data || null,
  };
};

export default connect(mapStateToProps)(EligibilityFileUpload);
