import { useSearchQueries } from '../../hooks/useSearch';
import Header from '../../components/common/global-top-bar';
import { AllSearches } from './all-searches';
import SwitchBar from '../../components/switch-bar';
import { TestSearch } from './test-search';

export const pages = [
  { heading: 'View', path: '/search-queries' },
  { heading: 'Test', path: '/search-queries/test' },
];

export const SearchQueriesPage = () => {
  const { data: searchQueries } = useSearchQueries();

  return (
    <>
      <Header heading="Search Queries" />
      <SwitchBar heading={pages} position={0} />

      <AllSearches searchQueries={searchQueries} />
    </>
  );
};

export const SearchQueriesTestPage = () => {
  return (
    <>
      <Header heading="Test Search Queries" />
      <SwitchBar heading={pages} position={1} />
      <TestSearch />
    </>
  );
};
