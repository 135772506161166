import React from 'react';
import { Route } from 'react-router';
import { NavigationBar } from '../containers';

type props = {
  exact: boolean;
  path: string;
  component: any;
  selectedId: string;
};

const PrivateRoute: React.FunctionComponent<props> = ({
  exact,
  path,
  component: Component,
  selectedId,
}): JSX.Element => {
  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        /*
        Didn't realize that we're making layout descisions in the private route.
        This isn't very useful
        In a better world this thing is prehaps a DashboardLayout, and doesn't have a router in it
        */
        <div className="flex  max-h-screen">
          <div className="flex">
            <NavigationBar selectedId={selectedId} />
          </div>
          <div className="flex-1 max-h-screen pl-64">
            <Component />
          </div>
        </div>
      )}
    />
  );
};

export default PrivateRoute;
