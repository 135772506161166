import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ROUTE_PATH } from '../../routes/paths';
import Heading from './heading';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

type props = {
  heading: String;
  childArray: [];
  selected: any;
  clients: any;
};

const SideBarChild: React.FunctionComponent<props> = ({ heading, childArray, selected, clients }): JSX.Element => {
  const history = useHistory();
  const pathName = useLocation().pathname;
  const { roleType } = useAuth();

  let hideEligibilty = true;
  if (clients?.length > 0 && roleType === 'client_administrator') {
    const verifyEligibility = clients.every((item: any) => !!item.eligibility_config?.hide_client_admin_eligibility);
    hideEligibilty = !!(verifyEligibility && roleType === 'client_administrator');
    if (!!(verifyEligibility && roleType === 'client_administrator' && pathName === ROUTE_PATH.ELIGIBILTY)) {
      history.push(ROUTE_PATH.SNAPSHOT);
    }
  }

  return (
    <div>
      {heading.length > 1 && <Heading heading={heading} />}
      <div className="relative ">
        {childArray &&
          childArray.map((item: any) => {
            if (!(hideEligibilty && item.text === 'Eligibility')) {
              return (
                <Link to={item.path}>
                  <div
                    // gah no
                    key={Math.random()}
                    role="button"
                    className={
                      'relative flex w-12/12 py-2 pl-7 ' +
                      (selected === item.id ? 'btn-blue text-white' : 'text-gray-dark')
                    }
                  >
                    <button className={'text-center font-bold  text-base '}>{item.text}</button>
                  </div>
                </Link>
              );
            }
          })}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    clients: state.Client.clients?.data || null,
  };
};

export default connect(mapStateToProps)(SideBarChild);
