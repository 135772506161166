import React from 'react';
import LoginLogo from '../../assets/images/login-logo.svg';
import useAuth from '../../hooks/useAuth';

const NotifyMessage: React.FunctionComponent = (): JSX.Element => {
  const webAppUrl = process.env.REACT_APP_MWA_LINK;
  const { logout } = useAuth();

  return (
    <div>
      <div className="flex h-screen bg-gray-100">
        <div className="w-full max-w-xl m-auto bg-white  py-10 px-5">
          <div className="flex justify-center">
            <img className="= ml-24  w-3/4" src={LoginLogo} alt="login" />
          </div>
          <div className="w-full text-center text-lg ">
            <p className="text-blue-primary font-extrabold  py-5  text-3xl">Hmmm?</p>
            <p className="text-gray-500 font-medium text-xl  py-2">That account does not have access.</p>
            <p className=" text-gray-500  font-medium  text-xl  py-2 pb-8">
              To access KindlyHuman as a member, visit our app:
            </p>
            <a
              href={webAppUrl}
              className="bg-gray-700 stacked-bar-blue-400 px-10 py-2 my-5 font-bold rounded-lg text-white"
            >
              GO TO APP
            </a>
            <p className="text-gray-500  font-medium  py-5" role="button" onClick={logout}>
              {' '}
              Close{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyMessage;
