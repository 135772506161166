import React, { useEffect, useState } from 'react';
import { LOCLogo } from '../../assets/images';
import { CustomInput } from '../../components/common';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmailAvailableRequest } from '../../components/account-login/login-redux/actions';
import useDebounce from '../../components/common/debounce';
import useAuth from '../../hooks/useAuth';
import { useCompleteOnboarding } from '../../hooks/useUsers';

type props = {
  UserDetails: any;
  authenticateWithToken: any;
  authenticateWithTokenError: any;
  pending: boolean;
  emailAvailable: any;
  emailAvailableError: any;
  emailAvailablePendding: boolean;
  clientAdminOnborading: any;
  clientAdminOnboradingError: any;
  tos: any;
};

const OnBoarding: React.FunctionComponent<props> = (props): JSX.Element => {
  const { emailAvailable } = props;
  const completeOnboarding = useCompleteOnboarding();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>(user?.email_address ?? '');
  const [firstName, setFirstName] = useState<string>(user?.first_name ?? '');
  const [lastName, setLastName] = useState<string>(user?.last_name ?? '');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [isAvailableEmail, setIsAvailableEmail] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const [isShowForm, setIsShowForm] = useState<boolean>(true);
  const [isTerms, setIsTerms] = useState<boolean>(false);

  const debouncedEmail = useDebounce(email, 500);

  const handleSubmit = () => {
    const trimEmail = email?.trim();
    const trimFirstName = firstName?.trim();
    const trimLastName = lastName?.trim();
    const trimPassword = password?.trim();

    setEmail(trimEmail);
    setFirstName(trimFirstName);
    setLastName(trimLastName);
    setPassword(trimPassword);

    const emailAddress = user?.email_address;

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(trimEmail) || trimEmail === '') {
      return setError({ email: 'Enter valid email address' });
    } else if (isAvailableEmail === false && emailAddress !== trimEmail) {
      return setError({ email: 'Email already exists' });
    } else if (trimFirstName === undefined || trimFirstName?.length < 2) {
      if (trimFirstName === '' || trimFirstName === undefined) {
        return setError({ firstName: 'Enter first name' });
      } else {
        return setError({ firstName: 'Enter min. 2 characters' });
      }
    } else if (trimLastName === undefined || trimLastName?.length < 2) {
      if (trimLastName === '' || trimLastName === undefined) {
        return setError({ lastName: 'Enter last name' });
      } else {
        return setError({ lastName: 'Enter min. 2 characters' });
      }
    }
    if (trimPassword === undefined || trimPassword === '') {
      return setError({ password: 'Enter password' });
    } else if (passwordConfirmation !== password) {
      return setError({ passwordConfirmation: 'password does not match' });
    } else {
      setError({});
    }
    setIsShowForm(false);
  };

  const handleTosSubmit = () => {
    if (!user) {
      console.log('we messed something up');
      return;
    }

    const input = {
      email_address: email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      userId: user?.id,
    };
    completeOnboarding.mutate(input, {
      onError: (e) => {
        toast.error(e.response.data.description);
      },
    });
  };

  useEffect(() => {
    if (emailAvailable) {
      setIsAvailableEmail(emailAvailable.data);
    }
  }, [emailAvailable]);

  useEffect(() => {
    if (debouncedEmail) {
      dispatch(fetchEmailAvailableRequest({ email }));
    }
  }, [debouncedEmail, dispatch, email]);

  return (
    <div>
      <div className="w-full h-screen bg-gray-background-light flex justify-center items-center">
        <div className="w-96 bg-white pt-8">
          <div className="text-primary w-full">
            {!isShowForm && (
              <button
                onClick={() => setIsShowForm(true)}
                className="my-2 mx-8 font-bold text-bright-blue pt-10 text-xl"
              >
                <svg
                  className="inline -mt-1 transform rotate-180 mr-2"
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.417 10a7.583 7.583 0 1115.166 0 7.583 7.583 0 01-15.166 0zM10 .917a9.083 9.083 0 100 18.166A9.083 9.083 0 0010 .917zm1.364 6.053a.75.75 0 00-1.061 1.06l1.22 1.22H6.667a.75.75 0 100 1.5h4.856l-1.22 1.22a.75.75 0 001.06 1.06l2.5-2.5a.75.75 0 000-1.06l-2.5-2.5z"
                    fill="#240089"
                  ></path>
                </svg>
                Back
              </button>
            )}
            <div className="flex justify-center pt-4 px-8">
              <img src={LOCLogo} alt="kindly-logo" className="w-56" />
            </div>
            {isShowForm ? (
              <div className="px-8 pb-12">
                <h3 className="text-bright-blue pt-10 text-xl">Create Your Account</h3>
                <div className="mt-8">
                  <CustomInput
                    inputType={'text'}
                    value={email}
                    setValue={setEmail}
                    Label={'Email'}
                    Error={error?.email}
                    ErrorMessage={error?.email}
                    labelClass="font-normal"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <CustomInput
                    inputType={'text'}
                    value={firstName}
                    setValue={setFirstName}
                    Label={'First Name'}
                    Error={error?.firstName}
                    ErrorMessage={error?.firstName}
                    labelClass="font-normal"
                  />
                  <CustomInput
                    inputType={'text'}
                    value={lastName}
                    setValue={setLastName}
                    Label={'Last Name'}
                    Error={error?.lastName}
                    ErrorMessage={error?.lastName}
                    labelClass="font-normal"
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'password'}
                    value={password}
                    setValue={setPassword}
                    Label={'Password'}
                    Error={error?.password}
                    ErrorMessage={error?.password}
                    labelClass="font-normal"
                    secondLable="Minimum 15 characters"
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'password'}
                    value={passwordConfirmation}
                    setValue={setPasswordConfirmation}
                    Label={'Confirm Password'}
                    Error={error?.passwordConfirmation}
                    ErrorMessage={error?.passwordConfirmation}
                    labelClass="font-normal"
                  />
                </div>
                <div className="flex justify-center mt-8">
                  <button
                    type="button"
                    className="bg-bright-blue rounded-full py-1.5 px-12 text-white"
                    onClick={handleSubmit}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h3 className="text-bright-blue pt-10 text-xl px-8">Terms of Service</h3>
                <div
                  className="bg-primary bg-opacity-2 overflow-y-auto h-96 px-8 pb-4 mt-4"
                  dangerouslySetInnerHTML={{
                    __html: props.tos?.data?.app ? props.tos?.data?.app?.CLIENT_ADMIN_TERMS_OF_SERVICE : null,
                  }}
                ></div>
                <div className="bg-bright-blue-10 pb-12">
                  <div className="px-8">
                    <label className="inline-flex items-top mt-3">
                      <input
                        type="radio"
                        className="form-radio h-5 w-5 text-gray-600 mt-1"
                        checked={isTerms}
                        onClick={() => setIsTerms(!isTerms)}
                      />
                      <span className="ml-2 text-gray-700 text-sm leading-5">
                        I accept the Terms of Service. I have read and understood how my data as described in the{' '}
                        <a href="https://www.listenersoncall.com/privacy" target="_blank" rel="noopener noreferrer">
                          <u>Privacy Statement</u>
                        </a>{' '}
                        will be processed.
                      </span>
                    </label>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      type="button"
                      className={`rounded-full py-1.5 px-12 text-white ${
                        !isTerms ? 'bg-gray-background-dark cursor-not-allowed' : 'bg-bright-blue'
                      }`}
                      onClick={handleTosSubmit}
                      disabled={!isTerms}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserDetails: state.login.UserDetails,
    authenticateWithToken: state.login?.authenticateWithToken,
    authenticateWithTokenError: state.login.authenticateWithTokenError,
    emailAvailable: state.login.emailAvailable,
    emailAvailableError: state.login.emailAvailableError,
    emailAvailablePendding: state.login.emailAvailablePendding,
    clientAdminOnborading: state.login.clientAdminOnborading,
    clientAdminOnboradingError: state.login.clientAdminOnboradingError,
    tos: state.Tos.clientAdmin?.data || null,
    pending: state.login.pending,
  };
};

export default connect(mapStateToProps)(OnBoarding);
