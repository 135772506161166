import React, { useEffect } from 'react';
import { useState, useCallback, useMemo } from 'react';
import { CustomInput, UseFormDropDown } from '../../../components/common';
import Modal from '../../../components/common/modal';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import { useUpdateResource, useAddResource, useRemoveResource, Resource } from '../../../hooks/useResources';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { decimalOnChange, numberonBlur, onBlur } from '../../../components/common/useForm';
import { useSearchForSupportTags } from '../../../hooks/useTagGroups';
import { ResourceTagsEditModal } from '../../resources/resource-tags-modal';

type props = {
  resource: Resource;
  clientId: number;
};

const EditClientResourceDetails: React.FunctionComponent<props> = ({ resource, clientId }): JSX.Element => {
  const { updateResource } = useUpdateResource(resource.id!);
  const { addResource } = useAddResource();
  const { removeResource } = useRemoveResource(resource.id!);
  const [modal, setModal] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [enableSaveOption, setEnableSaveOption] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [imageBase64, setImageBase64] = useState<string>();
  const [resourceLogoWide, setResourceLogoWide] = useState<string>();
  const [showTagsModal, setShowTagsModal] = useState(false);

  const { data: tags } = useSearchForSupportTags();

  const fileInput: React.RefObject<HTMLInputElement> = React.createRef();
  const fileInputWide: React.RefObject<HTMLInputElement> = React.createRef();
  const defaultResource = {
    ...resource,
    description: resource?.description || '',
    long_description: resource?.long_description || '',
    meta_keywords: resource?.meta_keywords || '',
    image_url: resource?.image_url || null,
    wide_image_url: resource?.wide_image_url || null,
    name: resource?.name || '',
    phone_number: resource?.phone_number || '',
    sort_weight: resource?.sort_weight || 0,
    status: resource?.status || 'live',
    url: resource?.url || '',
    tag_ids: resource?.tag_ids || [],
  };

  const handleTagsChange = (tags: number[]) => {
    setValue('tag_ids', tags, { shouldDirty: true });
    setShowTagsModal(false);
  };

  const onFileChange = (event: React.FormEvent<HTMLInputElement>, wideImage?: boolean) => {
    try {
      if (event.currentTarget.files?.[0]) {
        let reader = new FileReader();

        reader.onload = (e: any) => {
          //Set the Base64 string return from FileReader as source.
          const image = new Image();

          if (wideImage) {
            setValue('wide_image_url', e.target.result);
            setResourceLogoWide(e.target.result);
            setEnableSaveOption(true);
          } else {
            //Validate the File Height and Width.
            image.onload = () => {
              // @ts-ignore
              const height = image.height;
              // @ts-ignore
              const width = image.width;
              if (height === width) {
                setValue('image_url', e.target.result);
                setImageBase64(e.target.result);
                setEnableSaveOption(true);
              } else {
                toast.error('Image must be equal height and width.');
                return new Error('Image must be equal height and width.');
              }
            };
          }
          image.src = e.target.result;
        };
        reader.readAsDataURL(event.currentTarget.files[0]);
      }
    } catch (error) {
      console.error('err--', error);
    }
  };

  const handleImageInput = (name: string) => {
    if (name === 'image_url') {
      fileInput.current?.click();
    }
    if (name === 'wide_image_url') {
      fileInputWide.current?.click();
    }
  };

  const setImageDisplay = useCallback(() => {
    if (resource?.image_url) {
      setImageBase64(resource.image_url);
    } else if (imageBase64 === '') {
      setImageBase64('');
    }

    if (resource?.wide_image_url) {
      setResourceLogoWide(resource.wide_image_url);
    } else if (resourceLogoWide === '') {
      setResourceLogoWide('');
    }
  }, [resource, imageBase64, resourceLogoWide]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      setImageDisplay();
      if (resource) {
        return defaultResource;
      }
    }, [resource]),
  });

  const validatePhone = async (data: any) => {
    let isValid = false;
    const hasAlphabeticCharacters = /[a-zA-Z]/.test(data.phone_number);
    // Remove non digits and validate that the number is either 0 digits (null), exactly 3 digits (N11 codes),
    // or between 10 and 15 digits (phone numbers ranging from US area code + number to maximum international length)
    const phoneNumber = data.phone_number.replace(/\D/g, '');
    const phoneNumberRegex = /^\d{3}$|^\d{10,15}$/;
    if (hasAlphabeticCharacters) {
      setErrorMessages({ phone_number: 'Phone number cannot contain alphabetic characters' });
    } else if (phoneNumber.length > 0 && !phoneNumberRegex.test(phoneNumber)) {
      setErrorMessages({ phone_number: 'Please enter a valid 3 or 10-15 digit phone number' });
    } else {
      setErrorMessages({});
      isValid = true;
    }

    return isValid;
  };

  useEffect(() => {
    if (resource.id) {
      reset(defaultResource);
      setImageBase64(resource?.image_url || '');
      setResourceLogoWide(resource?.wide_image_url || '');
      setEnableSaveOption(false);
    }
  }, [resource]);

  const handleSave = async (data: any) => {
    const payload = {
      ...data,
      phone_number: data?.phone_number ? data?.phone_number.replace(/[^\d+]/g, '') : null,
      image_url: data?.image_url ? (enableSaveOption ? data?.image_url : undefined) : null,
      wide_image_url: data?.wide_image_url ? (enableSaveOption ? data?.wide_image_url : undefined) : null,
      client_id: clientId,
    };

    if ((await validatePhone(data)) && resource?.id !== undefined) {
      updateResource.mutate(payload);
      setModal(false);
      setAlertModal(false);
      setEnableSaveOption(false);
      imageBase64 && setImageBase64('');
      resourceLogoWide && setResourceLogoWide('');
    } else if ((await validatePhone(data)) && resource?.id === undefined) {
      addResource.mutate(payload);
      setModal(false);
      setAlertModal(false);
      setEnableSaveOption(false);
      reset();
      imageBase64 && setImageBase64('');
      resourceLogoWide && setResourceLogoWide('');
    }
  };

  const handleRemoveResource = async () => {
    removeResource.mutate();
    setModal(false);
    setAlertModal(false);
    setEnableSaveOption(false);
    reset();
  };

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          setModal(true);
        }}
        className="text-center text-sm text-white px-2 py-2 bg-blue-primary rounded-sm "
      >
        {resource.id ? 'Edit' : 'Add Resource +'}
      </button>
      <Modal
        isModel={modal}
        InnerComponent={
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          >
            <form onSubmit={handleSubmit(handleSave)} id="resource-form">
              <div className="bg-white px-2 pt-3 pb-2 sm:p-6 sm:pb-4">
                <div className="flex justify-start ">
                  <div>
                    <h3 className="text-2xl font-normal blue-primary py-1">
                      {resource.id ? 'Edit Resource' : 'Add Resource'}
                    </h3>
                  </div>
                </div>
                <div className="py-2 flex flex-start">
                  <h3 className="text-lg font-bold text-gray-dark py-1">Details</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 gap-y-5 mr-5">
                  <div>
                    <CustomInput
                      inputType="text"
                      Label="Resource Name"
                      register={{
                        ...register('name', {
                          required: 'Please provide resource name',
                          onBlur: (e: any) => onBlur(e, 'name', setValue),
                        }),
                      }}
                      //@ts-ignore
                      Error={!!errors?.name?.message?.length}
                      ErrorMessage={errors.name?.message}
                    />
                  </div>
                  <div className="text-left">
                    <UseFormDropDown label={'Status'}>
                      <select className="select-box w-full" defaultValue="published" {...register('status')}>
                        <option value="live">Live</option>
                        <option value="hidden">Hidden</option>
                      </select>
                    </UseFormDropDown>
                  </div>
                  <div>
                    <h3 className="block text-left leading-4 text-blue-dark mb-2">Description</h3>
                    <div className="w-full my-2">
                      <textarea
                        className="py-2 px-3 w-full rounded-md h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
                        {...register('description', {
                          required: 'Please provide resource description',
                          onBlur: (e: any) => onBlur(e, 'description', setValue),
                        })}
                      />
                      {
                        //@ts-ignore
                        !!errors?.description?.message?.length > 0 && (
                          <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                            {/* @ts-ignore */}
                            {errors.description?.message}
                          </label>
                        )
                      }
                    </div>
                  </div>
                  <div>
                    <h3 className="block text-left leading-4 text-blue-dark mb-2">Long Description</h3>
                    <div className="w-full my-2">
                      <textarea
                        className="py-2 px-3 w-full rounded-md h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
                        {...register('long_description', {
                          onBlur: (e: any) => onBlur(e, 'long_description', setValue),
                        })}
                      />
                      {errors?.meta_keywords?.message && errors.meta_keywords.message.length > 0 && (
                        <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                          {/* @ts-ignore */}
                          {errors.long_description?.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <h3 className="block text-left leading-4 text-blue-dark mb-2">Meta Keywords</h3>
                    <div className="w-full my-2">
                      <textarea
                        className="py-2 px-3 w-full rounded-md h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
                        {...register('meta_keywords', {
                          onBlur: (e: any) => onBlur(e, 'meta_keywords', setValue),
                        })}
                      />
                      {errors?.meta_keywords?.message && errors.meta_keywords.message.length > 0 && (
                        <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                          {/* @ts-ignore */}
                          {errors.meta_keywords?.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div>
                    <CustomInput
                      inputType="tel"
                      Label="Phone"
                      register={{
                        ...register('phone_number', {
                          onBlur: (e: any) => onBlur(e, 'phone_number', setValue),
                          onChange: (e) => {
                            validatePhone({ phone_number: e.target.value });
                          },
                        }),
                      }}
                      //@ts-ignore
                      Error={errorMessages.phone_number?.length > 0 ? true : false}
                      ErrorMessage={errorMessages.phone_number}
                    />
                  </div>
                  <div>
                    <CustomInput
                      inputType="text"
                      Label="Link"
                      register={{
                        ...register('url', {
                          required: 'Please provide resource link',
                          onBlur: (e: any) => onBlur(e, 'url', setValue),
                        }),
                      }}
                      //@ts-ignore
                      Error={!!errors?.url?.message?.length}
                      ErrorMessage={errors.url?.message}
                    />
                  </div>
                  <div className="text-left">
                    <p>Resource Image</p>
                    <p className="pt-1 py-2 text-sm">Displays in app as benefit provider</p>
                    <div className="w-full">
                      <div
                        className={`bg-gray-200 w-full aspect-ratio-container rounded-sm flex justify-center items-center ${
                          !imageBase64 && 'cursor-pointer'
                        }`}
                        {...(!imageBase64 && { onClick: handleImageInput.bind(this, 'image_url') })}
                      >
                        {imageBase64 ? (
                          <img className="border" src={imageBase64} alt="clientImage" />
                        ) : (
                          <div className="h-80 flex justify-center items-center">
                            <button
                              className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center "
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              +
                            </button>
                            <input
                              ref={fileInput}
                              id="file-upload"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onClick={() => {
                                if (fileInput.current) {
                                  fileInput.current.value = '';
                                }
                              }}
                              onChange={(data) => onFileChange(data)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="px-12">
                        {imageBase64 && (
                          <button
                            className="bg-blue-primary rounded-full text-center text-sm text-white p-2 w-full my-2"
                            onClick={() => {
                              setImageBase64('');
                              setValue('image_url', '');
                              setEnableSaveOption(true);
                            }}
                          >
                            REMOVE
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <CustomInput
                      inputType="text"
                      Label="Sort Order"
                      register={{
                        ...register('sort_weight', {
                          onChange: (e) => decimalOnChange(e, 'sort_weight', setValue, 2),
                          onBlur: (e) => numberonBlur(e, 'sort_weight', setValue),
                          valueAsNumber: true,
                        }),
                      }}
                      //@ts-ignore
                      Error={!!errors?.sort_weight?.message?.length}
                      ErrorMessage={errors.sort_weight?.message}
                    />
                    <button
                      className="bg-blue-primary rounded-full text-center text-sm text-white p-2 w-full my-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowTagsModal(true);
                      }}
                    >
                      TAGS
                    </button>
                    <div className="py-4 grid grid-cols-2 grid-flow-row gap-y-1  gap-x-4">
                      {getValues('tag_ids').map((id) => {
                        return (
                          <div className="p-0.25 font-medium font-manrope text-gray-800 text-sm leading-5 whitespace-nowrap block w-12">
                            <div className="w-2 h-2 mr-2 rounded inline-block bg-blue-primary"></div>
                            {tags?.find((x) => x.id === id)?.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="text-left col-span-2">
                    <p className="pt-10 font-normal text-gray-dark">Wide Resource Image</p>
                    <p className="pt-1 py-2 font-normal text-gray-dark">
                      Displays when peers are associated to resources
                    </p>
                    <p className="pt-1 py-2 font-normal text-red text-sm">
                      Wide resource images will look best with a 16:9 aspect ratio, a minimum resolution of 1280 x 720,
                      and as a transparent .png.
                    </p>
                    <div className="w-full flex">
                      <div
                        className={`${
                          !resourceLogoWide && 'cursor-pointer'
                        } bg-gray-background-light  rounded-sm  w-full  aspect-ratio-container`}
                        {...(!resourceLogoWide && { onClick: handleImageInput.bind(this, 'wide_image_url') })}
                      >
                        {resourceLogoWide ? (
                          <div className="aspect-ratio-div">
                            <img
                              className=" aspect-ratio-container-img "
                              src={resourceLogoWide}
                              alt="resourceLogoWide"
                            />
                          </div>
                        ) : (
                          <div className="h-56 flex justify-center items-center">
                            <button
                              className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              +
                            </button>
                            <input
                              ref={fileInputWide}
                              id="file-upload"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onClick={() => {
                                if (fileInputWide.current) {
                                  fileInputWide.current.value = '';
                                }
                              }}
                              onChange={(data) => onFileChange(data, true)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="pl-4">
                        <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                        <div className="w-full aspect-ratio-container pt-2">
                          {resourceLogoWide && (
                            <div className="w-full aspect-ratio-container ">
                              <button
                                className="bg-blue-primary rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                                onClick={() => {
                                  setResourceLogoWide('');
                                  setValue('wide_image_url', '');
                                  setEnableSaveOption(true);
                                }}
                              >
                                REMOVE
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    className={
                      'text-center h-8 text-sm text-white px-2 w-16 rounded-sm ' +
                      (isDirty || enableSaveOption ? 'bg-blue-primary' : 'bg-gray-200')
                    }
                    type="submit"
                    form="resource-form"
                    disabled={!isDirty && !enableSaveOption}
                  >
                    Save
                  </button>
                  <button
                    className="text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4 "
                    type="button"
                    onClick={() => {
                      if (isDirty || enableSaveOption) {
                        setAlertModal(true);
                      } else {
                        setModal(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                  {resource.id && (
                    <button
                      className="text-center h-8 text-sm text-white px-2 bg-blue-primary rounded-sm ml-4 "
                      type="button"
                      onClick={handleRemoveResource}
                    >
                      Remove Resource
                    </button>
                  )}
                </div>
              </div>
            </form>
            {tags && showTagsModal && (
              <ResourceTagsEditModal
                onChange={handleTagsChange}
                onExit={() => setShowTagsModal(false)}
                selectedTagIds={getValues('tag_ids')}
                tags={tags}
              />
            )}
          </div>
        }
      />
      <AlertBox
        visible={alertModal}
        handlePrompt={() => {
          reset(defaultResource);
          setImageBase64('');
          setImageDisplay();
          setModal(false);
          setAlertModal(false);
          setEnableSaveOption(false);
        }}
        closeModal={() => {
          setAlertModal(false);
        }}
        confirmButtonText={'Discard Changes'}
        titleText={'Alert'}
        contentText={'You have unsaved changes. If you leave this screen without saving, your changes will be lost.'}
        cancelButtonText={'Cancel'}
      />
    </>
  );
};

export default EditClientResourceDetails;
