import React from 'react';

/*
This is a hook made to create a state that's synced with a localStorage value.
This pattern is useful for times where you want to:
  1. re-render the page based on updates to localStorage, basically functioning as a state manager
  2.  enable react-query based on localStorage, which is useful for our current pattern of the authorization_token
*/
const useStickyState = (key: string, defaultValue: string | null): [string | null, React.Dispatch<string | null>] => {
  const [value, setStateValue] = React.useState(() => {
    const stickyValue = localStorage.getItem(key);
    return stickyValue ? stickyValue : defaultValue;
  });

  const setValue = (value: string | null) => {
    if (value === null) {
      localStorage.removeItem(key);
      setStateValue(null);
    } else {
      localStorage.setItem(key, value);
      setStateValue(value);
    }
  };
  return [value, setValue];
};

export default useStickyState;
