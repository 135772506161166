import React from 'react';
import useAuth from '../hooks/useAuth';
import { AdminRouter } from './admin-router';
import { ClientAdminRouter, ClientOnboardingRouter } from './clientadmin-router';
import { UserRole } from '../containers/navigation-bar/roles';
import { WrongUserRouter } from './wrong-user-router';

const AuthenticatedRouter: React.FC = () => {
  /*
    check what type of role we have and adjust routes accordingly
  */
  const { user, roleType } = useAuth();

  const renderRouter = (): React.ReactElement => {
    // this is interesting, because hasCompletedOnboarding will default to true if the user doesn't have an admin or client admin role
    if (!user?.hasCompletedOnboarding && user?.client_administrator_role_id) {
      return <ClientOnboardingRouter />;
    }
    // admin
    if (roleType === UserRole.admin) {
      return <AdminRouter />;
    }
    // client admin
    if (roleType === UserRole.client_admin) {
      return <ClientAdminRouter />;
    }
    // you don't have any roles that fit what matches this app :(
    // could look for listener_role or caller_role here if desired
    return <WrongUserRouter />;
  };

  return <>{renderRouter()}</>;
};

export default AuthenticatedRouter;
