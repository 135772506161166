import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { XCricle, Refresh, CancelIcon } from '../../assets/images';
import { UserRole } from '../../containers/navigation-bar/roles';
import { useCancelEligibilityFile, useEligibilityFile } from '../../hooks/useEligibility';
import useAuth from '../../hooks/useAuth';

type props = {
  data: any;
  clientId: number;
  tableType: string;
  handleEligibilityFileViewModel: (id: number, type: string) => void;
  isACtiveAndScheduleShow?: boolean;
  handleDeleteEligibility?: (id: number) => void;
  handleProcessEligibility?: (id: number) => void;
  handleReplaceEligibility?: (id: number, date: string) => void;
  handleTranferEligibility?: (id: number) => void;
  handleRefresh?: (type: string) => void;
};
const EligibilityTableList: React.FunctionComponent<props> = (props): JSX.Element => {
  const { data, clientId } = props;
  const [eligibilityId, setEligibilityId] = useState<number>(data?.length ? data[0].id : undefined);
  const [enabled, setEnabled] = useState<boolean>(false);
  const { refetch } = useEligibilityFile(clientId, eligibilityId, enabled);
  const cancelMutation = useCancelEligibilityFile();
  const { roleType: userType } = useAuth();
  const [showOptimisticProcessing, setShowOptimisticProcessing] = useState<boolean>(false);

  const cancelProcess = (eligibilityProcessId: number) => {
    cancelMutation.mutate(
      { clientId: clientId, datasetId: eligibilityProcessId },
      {
        onSettled: () => {
          props.handleRefresh && props.handleRefresh(props.tableType);
          setShowOptimisticProcessing(false);
        },
      },
    );
  };

  const handleDownloadEligibility = (eligibilityDatasetId: number) => {
    setEligibilityId(eligibilityDatasetId);
    if (eligibilityDatasetId) {
      setEnabled(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
      setEnabled(false);
    };
    if (enabled && eligibilityId) {
      fetchData();
    }
  }, [enabled, eligibilityId, refetch]);

  // Reset optimistic processing state on reload so that item.status can be the source of truth. We set it to true on click to give the user immediate feedback.
  // I believe that in Stage the `?active` fetch comes back too quickly and still shows the file as `item.status = validation_pass` when we're expecting `item.status = in_progress` and seeing that in lower environments
  useEffect(() => {
    setShowOptimisticProcessing(false);
  }, []);

  return (
    <div>
      <div className="px-7 ">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              {props.tableType === 'scheduled' && userType === UserRole.client_admin ? (
                <>
                  <th className="px-2 py-1 w-1/2 sticky top-0  bg-white  h-full mb-4 font-normal">Live Date</th>
                  <th className="w-1/2"></th>
                </>
              ) : (
                <>
                  <th className="px-2 py-1 w-2/12 sticky top-0  bg-white  h-full mb-4 font-normal">Members</th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Live Date
                  </th>

                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    New
                  </th>

                  <th className="px-2 py-1  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Existing
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Removed
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Invalid
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Uploaded / Pulled
                  </th>
                  {props.isACtiveAndScheduleShow && (
                    <>
                      <th
                        className={`${
                          userType === UserRole.admin ? 'w-2/5' : 'w-20'
                        } px-2 py-2 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal`}
                      ></th>
                    </>
                  )}
                  <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    {userType === UserRole.admin ? (
                      <button
                        type="button"
                        className="w-full h-full flex justify-center items-center"
                        onClick={() => props.handleRefresh && props.handleRefresh(props.tableType)}
                      >
                        <img src={Refresh} alt="refresh-icon" className="w-6 h-6" />
                      </button>
                    ) : null}
                  </th>
                  <th className="w-2/12"></th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {data?.length ? (
              data.map((item: any) => {
                return (
                  <tr
                    key={item.id}
                    className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                  >
                    {props.tableType === 'scheduled' && userType === UserRole.client_admin ? (
                      <>
                        <td className="py-1 w-2/12 blue-primary border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {props.isACtiveAndScheduleShow
                                ? moment(item?.billing_cycle).format('YYYY-MM-DD')
                                : moment(item.completed_at).format('YYYY-MM-DD')}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <button
                            type="button"
                            className="ml-2 flex items-center"
                            onClick={() => props.handleDeleteEligibility && props.handleDeleteEligibility(item.id)}
                          >
                            <img src={XCricle} alt="x-circle" />
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="py-1 w-2/12 text-gray-dark text-bold font-bold border-bottom">
                          {item.validation_report?.record_count}
                        </td>
                        <td className="py-1 w-2/12 blue-primary border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {props.isACtiveAndScheduleShow
                                ? moment(item?.billing_cycle).format('YYYY-MM-DD')
                                : moment(item.completed_at).format('YYYY-MM-DD')}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.added_members_count}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.existing_members_count}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.removed_members_count}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.invalid_records_count}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2 capitalize">{item.process_type}</p>
                          </div>
                        </td>
                        {props.isACtiveAndScheduleShow && (
                          <>
                            <td className={`py-1 border-bottom`}>
                              <div className="w-full flex items-center">
                                {userType === UserRole.admin ? (
                                  <>
                                    {item.status === 'transfer_pending' ? (
                                      <button
                                        className="px-2 py-1 text-sm btn-darkblue"
                                        onClick={() =>
                                          props.handleTranferEligibility && props.handleTranferEligibility(item.id)
                                        }
                                      >
                                        IMPORT
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          className="px-2 py-1 text-sm btn-darkblue whitespace-nowrap"
                                          onClick={() => {
                                            setShowOptimisticProcessing(true);
                                            if (props.handleProcessEligibility) {
                                              props.handleProcessEligibility(item.id);
                                            }
                                          }}
                                          disabled={item.status === 'in_progress'}
                                        >
                                          {item.status === 'in_progress' || showOptimisticProcessing
                                            ? `PROCESSING...${item.percentage_complete ? `: ${item.percentage_complete}` : ''}`
                                            : 'PROCESS'}
                                        </button>
                                        <button
                                          className="px-2 py-1 text-sm btn-darkblue ml-2"
                                          type="button"
                                          onClick={() =>
                                            props.handleReplaceEligibility &&
                                            props.handleReplaceEligibility(item.id, item.scheduled_at)
                                          }
                                        >
                                          REPLACE
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : null}
                                {item.percentage_complete?.includes('%') || showOptimisticProcessing ? (
                                  <span className=" pl-2 flex flex-col justify-center items-center">
                                    <label htmlFor="cancelButton">Reset</label>
                                    <button
                                      id="cancelButton"
                                      className="ml-2 flex items-center"
                                      onClick={() => cancelProcess(item.id)}
                                    >
                                      <img src={CancelIcon} alt="cancel-icon" className="w-6 h-6" />
                                    </button>
                                  </span>
                                ) : (
                                  <button
                                    type="button"
                                    className="ml-2 flex items-center"
                                    onClick={() =>
                                      props.handleDeleteEligibility && props.handleDeleteEligibility(item.id)
                                    }
                                  >
                                    <img src={XCricle} alt="x-circle" />
                                  </button>
                                )}
                              </div>
                            </td>
                          </>
                        )}
                        <td className="py-1 w-1/12 border-bottom">
                          {item.status !== 'transfer_pending' && (
                            <button
                              className="blue-primary"
                              onClick={() => props.handleEligibilityFileViewModel(item.id, props.tableType)}
                            >
                              View
                            </button>
                          )}
                        </td>
                        <td className="py-1 w-1/12 border-bottom">
                          {item.csv_path && (
                            <button className="blue-primary ml-2" onClick={() => handleDownloadEligibility(item.id)}>
                              Download
                            </button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center ext-gray-dark h-20">
                  {props.tableType === 'active' && 'No Active Eligibility'}
                  {props.tableType === 'scheduled' && 'No Scheduled Eligibility'}
                  {props.tableType === 'historical' && 'No Historic Eligibility'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default EligibilityTableList;
