import { Route } from 'react-router';
import { Login } from '../containers';
import { publicRoutes, tokenErrorRoutes } from './public-router';
import useAuth from '../hooks/useAuth';

export const UnauthenticatedRouter = () => {
  const { tokenError } = useAuth();
  if (tokenError) {
    return (
      <>
        {tokenErrorRoutes.map((route) => (
          <Route path="*" component={route.component} />
        ))}
      </>
    );
  }

  // These route are avaiable ONLY to unauthenticated users
  return (
    // Routes here anyone could see
    <>
      {publicRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      <Route component={Login} path="*" />
    </>
  );
};
