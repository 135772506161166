import React, { useEffect, useState } from 'react';
import { Pagination, Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import { useHistory } from 'react-router';
import Modal from '../../components/common/modal';
import Searchbar from '../../components/common/searchbar';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import { crisis, log, report } from '../../assets/images';
import { convertObjectToParams } from '../../components/common/create-params';
import { ROUTE_PATH } from '../../routes/paths';
import { Client, useListClients } from '../../hooks/useClients';
import {
  Connection,
  Count,
  TopicTag,
  Event,
  useConnections,
  useConnectionsCount,
  useEvents,
} from '../../hooks/useConnections';
import { convertToHoursMinuteSeconds, connectionOutcomeText, generateResources } from '../../components/connections';
import '@kindlyhuman/component-library/dist/style.css';
import { Toggle } from '@kindlyhuman/component-library';

const Connections: React.FC = (): JSX.Element => {
  const history = useHistory();
  const timeZone = moment.tz(moment.tz.guess()).zoneName();
  const [enableCallPopUp, setEnableCallPopUp] = useState(false);
  const [filters, setFilters] = useState<{
    page: number;
    limit: number;
    search?: string;
    clientId?: number;
  }>({
    page: 1,
    limit: 25,
  });
  const { page, limit, search, clientId } = filters;
  const [callId, setCallId] = useState<number | undefined>(undefined);
  const [requestId, setRequestId] = useState<number | undefined>(undefined);
  const [testUserState, setTestUserState] = useState<'live' | 'test'>('live');
  const { data: count, refetch: refetchCount } = useConnectionsCount({
    search,
    testUserState,
    clientId,
  });
  const { data: clients } = useListClients();
  const {
    data: connections,
    isLoading,
    refetch: refetchConnections,
  } = useConnections({ page, limit, search, testUserState, clientId });
  const { data: callLog } = useEvents(callId, requestId);

  const handlePeer = (peer: any) => {
    history.push(
      ROUTE_PATH.LISTENERS_PROFILE +
        convertObjectToParams({
          listenerRoleId: peer.user.listener_role_id,
          listenerId: peer.user.id,
          listenerType: 'listener',
          listenerName: peer.user.first_name,
        }),
    );
  };
  const handleCaller = (callerRole: any) => {
    history.push(
      ROUTE_PATH.MEMBERS_PROFILE +
        convertObjectToParams({
          name: callerRole.user.first_name,
          id: callerRole.user.id,
          callerRoleId: callerRole.id,
        }),
    );
  };

  const toggleUserState = () => {
    setTestUserState((prev) => (prev === 'live' ? 'test' : 'live'));
  };

  useEffect(() => {
    refetchConnections();
    refetchCount();
  }, [filters, refetchConnections, refetchCount]);

  return (
    <div>
      <Header userName={'Jason'} heading={<p>Calls</p>} />
      <Filters
        userState={testUserState}
        toggleUserState={toggleUserState}
        count={count}
        clients={clients}
        setFilters={setFilters}
      />
      {isLoading && <Spinner alignmentClass={'left-64 top-30 fixed z-100 inset-0 overflow-y-auto'} />}

      <div className="max-window-height-connections overflow-y-auto px-7 ">
        <div className="table-overflow">
          <table className="table-fixed table-row-format w-screen   overflow-x-scroll relative border-collapse">
            <thead className="bg-white  h-full ">
              <tr className=" text-left  w-full px-7">
                <th className=" py-2  sticky top-0  bg-white  h-full mb-4 w-8"></th>
                <th className=" py-2  sticky top-0  bg-white  h-full mb-4 w-44">
                  <p className="px-2">Peer</p>
                </th>
                <th className=" py-2  sticky top-0  bg-white  h-full mb-4 w-44">
                  <p className="px-2">Member</p>
                </th>
                <th className=" sticky top-0 bg-white  h-full mb-4 text-left w-52">Date/Time</th>
                <th className=" sticky top-0 bg-white  h-full mb-4 text-left w-52">Request Time</th>
                <th className=" sticky top-0 bg-white  h-full mb-4 w-24 ">Length</th>
                <th className=" sticky top-0 bg-white  h-full mb-4 w-24">Type</th>
                <th className=" sticky top-0 bg-white  h-full mb-4  w-44">Topic</th>
                <th className=" sticky top-0 bg-white  h-full mb-4  w-56">Topics Discussed</th>
                <th className=" sticky top-0 bg-white h-full mb-4 w-40">
                  <p className="pl-2">Outcome</p>
                </th>
                <th className=" sticky top-0 bg-white h-full mb-4 w-40">
                  <p className="pl-2">Result</p>
                </th>
                <th className=" sticky top-0 bg-white  h-full mb-4  w-36">Impact Score</th>
                <th className=" sticky top-0 bg-white  h-full mb-4  w-40">Call Reported?</th>
                <th className=" sticky top-0 bg-white  h-full mb-4  w-48">Mood (Before/After)</th>
                <th className=" sticky top-0 bg-white  h-full mb-4  w-24">Resources</th>
                <th className="px-1 py-2  sticky top-0 bg-white  h-full mb-4 text-right  z-50 w-20">
                  <p className="px-3">Log</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2 "></tr>
              {connections &&
                connections.length > 0 &&
                connections.map((connection: Connection) => {
                  const callDate = connection?.scheduled_at
                    ? connection.scheduled_at
                    : connection?.created_at && connection?.created_at;
                  const startDate = callDate ? moment(callDate).local().format('MMM Do YYYY') : '-';
                  const time = callDate ? moment(callDate).format('HH:mm') : '-';
                  const peer = connection?.listener_role;
                  const callerRole = connection?.request?.caller_role;
                  return (
                    <tr className="bg-gray-200 rounded text-gray-dark text-left h-12 border-y border-white border-4 space-y-6">
                      <td>{connection?.crisis_elevation_id && <img src={crisis} alt="crisis"></img>}</td>
                      <td
                        className="pl-2 cursor-pointer underline"
                        onClick={() => {
                          handlePeer(peer);
                        }}
                      >
                        {' '}
                        {`${peer?.user?.first_name}`}
                      </td>
                      <td
                        className="pl-2 cursor-pointer underline"
                        onClick={() => {
                          handleCaller(callerRole);
                        }}
                      >
                        {`${callerRole?.user?.first_name}`}
                      </td>
                      <td className=" ">
                        {startDate} / {time} {timeZone}
                      </td>
                      {/* requested at time */}
                      <td className=" ">
                        {moment(connection.request.created_at).local().format('MMM Do YYYY [/] HH:mm')} {timeZone}
                      </td>
                      <td className=" ">
                        {connection?.request?.call
                          ? convertToHoursMinuteSeconds(connection?.request?.call.logged_duration)
                          : `--`}
                      </td>
                      <td className=" ">
                        <button
                          className={
                            'w-full  rounded-full px-2 py-2 text-xs text-center text-white cursor-auto' +
                            (connection.scheduled_at ? ' bg-magenta ' : ' bg-violet')
                          }
                        >
                          {connection.scheduled_at ? ' Scheduled ' : ' Now '}
                        </button>
                      </td>
                      <td className="px-2 py-1 ">
                        <button
                          className={
                            'w-full h-full rounded-md px-2 py-2 text-xs text-center text-white bg-blue-gradient-1  cursor-auto'
                          }
                        >
                          {connection.request.topic ? connection.request.topic.name : ' No Topic '}
                        </button>
                      </td>
                      <td className="px-2 py-1 ">
                        <TopicsDiscussed topics={connection.request.call?.postcall_metadata?.topic_tags} />
                      </td>
                      <td className="px-2 py-1 ">
                        <button
                          className={
                            'w-full h-full rounded-full px-2 py-2 text-xs text-center text-white cursor-auto ' +
                            (connection.outcome === 'Call Scheduled'
                              ? 'bg-blue-gradient-1'
                              : connection.outcome === 'Call'
                                ? 'bg-green'
                                : connection.outcome === 'Short Call'
                                  ? ' bg-magenta '
                                  : connection.outcome === 'Passed'
                                    ? ' bg-orange '
                                    : connection.outcome === 'Missed'
                                      ? ' bg-red '
                                      : connection.outcome === 'Pending Response'
                                        ? ' '
                                        : connection.outcome === 'Cancelled'
                                          ? ' bg-red '
                                          : connection.outcome === 'Rescheduled'
                                            ? ' bg-violet'
                                            : ' bg-black ')
                          }
                        >
                          {connection.outcome ? connectionOutcomeText(connection.outcome, connection.expired) : '-'}
                        </button>
                      </td>
                      <td className="px-2 py-1 ">{connection.result ? connection.result : '-'}</td>
                      <td
                        className={
                          'w-full h-full rounded-md px-2 py-2 text-xs text-center text-black font-bold  cursor-auto'
                        }
                      >
                        {connection.request?.call?.impact_score ? connection.request.call.impact_score.toFixed(2) : '-'}
                      </td>
                      <td className=" ">
                        {connection.request.call?.postcall_metadata?.call_reported ? (
                          <img src={report} alt={'call_reported'} />
                        ) : (
                          'No'
                        )}
                      </td>
                      <td className=" ">
                        {connection.request.call?.postcall_metadata ? (
                          <>
                            <span className="text-red font-bold ">
                              {connection.request.call?.postcall_metadata?.begin_call_mood?.toFixed(2) || 0.0}
                            </span>
                            <span>{' / '}</span>
                            <span className="text-green font-bold ">
                              {connection.request.call?.postcall_metadata?.end_call_mood?.toFixed(2) || 0.0}
                            </span>{' '}
                          </>
                        ) : (
                          '--'
                        )}
                      </td>
                      <td className=" ">
                        {connection?.request?.call?.postcall_metadata
                          ? generateResources(connection?.request?.call?.postcall_metadata?.resources)
                          : '--'}
                      </td>
                      <td className=" ">
                        <div className="flex items-center justify-end px-2">
                          <button
                            className="rounded-full h-full blue-primary transform rotate-180 "
                            onClick={() => {
                              connection?.request?.call_id
                                ? setCallId(connection?.request?.call_id)
                                : setRequestId(connection?.request.id);
                              setEnableCallPopUp(true);
                            }}
                          >
                            <img className="h-8 w-full" src={log} alt="call details"></img>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Modal
          isModel={enableCallPopUp}
          InnerComponent={
            <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-5xl min-w-3xl max-h-162.5 overflow-y-auto">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Call Details:</div>
                    <div className="flex items-center justify-center px-10 pt-10">
                      <button
                        className="px-7 py-2 text-sm bg-gray-light text-white text-center rounded-full"
                        type="button"
                        onClick={() => {
                          setEnableCallPopUp(false);
                          setCallId(undefined);
                          setRequestId(undefined);
                        }}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                  <table className="table-fixed table-row-format relative border-collapse mx-10 my-2">
                    <thead className="bg-white  h-full ">
                      <tr className=" text-left  w-full px-7">
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          TimeStamp
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Event Category
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Event name
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {callLog && callLog.length > 0 ? (
                        callLog?.map((event: Event) => (
                          <tr
                            role="button"
                            className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                          >
                            <td className="py-1 w-1/4 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                  {moment(event.timestamp).format('ddd , MMMM Do YYYY, h:mm:ss a')}
                                </p>
                              </div>
                            </td>
                            <td className="py-1 w-1/4 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                  {event.event_category}
                                </p>
                              </div>
                            </td>
                            <td className="py-1 w-1/4 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">{event.event_name}</p>
                              </div>
                            </td>
                            <td className="py-1 w-1/4 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">{event.message}</p>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          role="button"
                          className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                        >
                          <td className="py-1 w-1/2 border-bottom">
                            <div className="flex">
                              <p className="text-gray-dark text-bold font-bold pr-2 text-base">No Data Available</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

const Filters: React.FC<{
  count?: Count;
  clients?: Client[];
  userState: 'live' | 'test';
  toggleUserState: () => void;
  setFilters: React.Dispatch<
    React.SetStateAction<{
      page: number;
      limit: number;
      search?: string;
      clientId?: number;
    }>
  >;
}> = ({ count, clients, setFilters, userState, toggleUserState }) => {
  return (
    <div className="w-full border-b gray-border-line flex items-center justify-between bg-gray-background-light px-7 h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setFilters((prev) => {
                return { ...prev, search: data ? data : undefined, page: 1 };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Pagination
          pageCount={Math.ceil((count?.count || 10) / 25)}
          key={count?.count}
          updatePageNumber={(data) => {
            setFilters((prev) => {
              return { ...prev, page: data + 1 };
            });
          }}
        />
      </div>
      <div className="flex flex-row gap-x-2">
        <div>Is Test User</div>
        <Toggle
          checked={userState === 'test'}
          onChange={() => {
            toggleUserState();
          }}
        />
      </div>
      <div className="">
        <label className="">Member's Group: {''}</label>
        <select
          className="w-24"
          onChange={(e) => {
            setFilters((prev) => {
              return {
                ...prev,
                clientId: e.target.value ? parseInt(e.target.value) : undefined,
                page: 1,
              };
            });
          }}
        >
          <option value="">Select</option>
          {clients?.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const TopicsDiscussed: React.FC<{ topics: TopicTag[] }> = ({ topics }): JSX.Element => {
  const generateTopics = (topics: TopicTag[]) => {
    return topics.map((topic: TopicTag) => topic.tag.name);
  };

  if (topics?.length === 1) {
    return <p>{topics[0].tag.name}</p>;
  } else if (topics?.length > 1) {
    return (
      <div>
        {topics[0].tag.name} and <ToolTip text={`${topics.length - 1} more`} list={generateTopics(topics.slice(1))} />
      </div>
    );
  }
  return <p>--</p>;
};

const ToolTip: React.FC<{ text: string; list: string[] }> = ({ text, list }): JSX.Element => {
  return (
    <>
      {' '}
      <a data-tip data-for={list[0]} className="underline cursor-pointer">
        {text}
      </a>
      <ReactTooltip id={list[0]} type="dark" place="bottom" effect="float">
        <ul>
          {list.map((tag: string) => (
            <li>{tag}</li>
          ))}
        </ul>
      </ReactTooltip>
    </>
  );
};

export default Connections;
