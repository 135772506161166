import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { sortArrow } from '../../assets/images';
import { ROUTE_PATH } from '../../routes/paths';
import { useInvoiceReport } from '../../hooks/useReporting';
import Modal from '../../components/common/modal';
import { Button, Toggle } from '@kindlyhuman/component-library';
import { clientTypeFilterOptions, useAllClientSummaries, useClientSummaryList } from '../../hooks/useClients';
import Searchbar from '../../components/common/searchbar';
import { Paginator } from '../../components/common/paginator';

import '@kindlyhuman/component-library/dist/style.css';

type props = {
  pending: any;
  clientPending: any;
};

const Clients: React.FunctionComponent<props> = (props): JSX.Element => {
  const [selection, setSelection] = useState(0);
  const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTypeFilterIndex, setActiveTypeFilterIndex] = useState(0);
  const [countsByType, setCountsByType] = useState<any>({
    all: 0,
    setup: 0,
    live: 0,
    inactive: 0,
  });

  const history = useHistory();

  const [filters, setFilters] = useState({
    search: '',
    type: 'ALL',
    is_test_client: false,
    order_by_direction: 'asc',
    order_by: 'name',
  });

  const { data: allClients } = useAllClientSummaries();
  const { data: clientSummaries, isFetching, refetch } = useClientSummaryList(filters);

  const handleSort = (type: any) => {
    setFilters({
      ...filters,
      order_by: type,
      order_by_direction:
        filters.order_by === type ? (filters?.order_by_direction === 'desc' ? 'asc' : 'desc') : 'desc',
    });
  };

  const filterClientType = (typeFormatted: string, search: string = '', newIsTestClient: boolean = false) => {
    setFilters({ ...filters, is_test_client: newIsTestClient, type: typeFormatted.toLowerCase(), search: search });
  };

  const clientCount = clientSummaries?.length ?? 0;
  const clientsCurrentPage = clientSummaries?.slice((currentPage - 1) * 100, currentPage * 100) ?? [];

  const addClient = () => {
    localStorage.removeItem('clientid');
    history.push('/groups/configure');
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    const setup = allClients?.filter((x) => x.status === 'setup').length ?? 0;
    const live = allClients?.filter((x) => x.status === 'active').length ?? 0;
    const inactive = allClients?.filter((x) => x.status === 'inactive').length ?? 0;
    const all = live + setup;
    setCountsByType({ all: all, setup: setup, live: live, inactive: inactive });
  }, [allClients]);

  return (
    <div>
      {isFetching && <Spinner />}
      <Header heading={'All Groups'} />
      <div className="w-full bg-gray-background-light px-6 ">
        <div className="flex justify-between items-center  gray-background-light text-gray-dark h-full">
          <div className="w-1/2 flex h-full py-2">
            <div className="w-full h-full">
              {/* weird error about can't be used as JSX component... */}
              <Searchbar
                search={(data) => {
                  setFilters((prev) => ({ ...prev, search: data }));
                  filterClientType(filters.type, data, filters.is_test_client);
                }}
              />
            </div>
            <div className="w-1/4">
              <button className="bg-white rounded-full w-full h-full py-1 mx-2 text-center" onClick={addClient}>
                ADD +
              </button>
            </div>
          </div>
          <div className="flex flex-row gap-x-2">
            <div>Is Test Client</div>
            <Toggle
              checked={filters.is_test_client}
              onChange={() => {
                setFilters((prev) => ({ ...prev, is_test_client: !prev.is_test_client }));
                filterClientType(filters.type, filters.search, !filters.is_test_client);
              }}
            />
          </div>
          <div className="flex font-medium justify-between items-center text-gray-dark ">
            <div className="flex justify-between items-center py-1  gray-background-dark">
              {clientTypeFilterOptions.map((item, index) => (
                <button
                  className={
                    ' text-sm  px-5 py-1 ' +
                    (index === activeTypeFilterIndex
                      ? 'bg-blue-primary text-white'
                      : 'bg-gray-background-dark text-gray-dark')
                  }
                  onClick={() => {
                    setActiveTypeFilterIndex(index);
                    setFilters((prev) => ({ ...prev, type: item }));
                    filterClientType(item, filters.search, filters.is_test_client);
                  }}
                >
                  {` ${item} (${countsByType[item.toLowerCase()]}) `}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center  gray-background-light text-gray-dark h-full">
          <div className="w-3/4">
            <Paginator
              count={clientCount || 0}
              limit={100}
              currentPage={currentPage}
              handlePageChange={(newPage: number) => {
                setCurrentPage!(newPage);
              }}
            />
          </div>
          <div className="w-1/4">
            {/* button that says Download Invoice Report */}
            <button
              className="bg-white rounded-full w-full h-full py-1 mx-2 text-center"
              onClick={() => setInvoiceModalOpen(true)}
            >
              Download Invoice Report
            </button>
          </div>
        </div>
      </div>
      {/* END client header */}
      <div className="max-window-height overflow-y-auto px-7">
        <table className="table-fixed table-row-format w-full relative border-collapse mb-5">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7 text-sm">
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div className="flex items-center pl-1 ">Status</div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div
                  className="flex items-center"
                  role="button"
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Name{' '}
                  {filters.order_by === 'name' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="sort by name"
                    ></img>
                  )}
                </div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div className="flex items-center">Eligible</div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div className="flex items-center">Active</div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div className="flex items-center">Reseller</div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div
                  className="flex items-center"
                  role="button"
                  onClick={() => {
                    handleSort('contract_date');
                  }}
                >
                  Contract Date{' '}
                  {filters.order_by === 'contract_date' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="sort by date"
                    ></img>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {props.pending || props.clientPending ? (
              <Spinner />
            ) : (
              clientsCurrentPage?.map((item: any, index: number) => {
                return (
                  <tr
                    key={item.id}
                    className={
                      ' rounded h-12 w-full text-left blue-primary border-y border-white border-4 space-y-6' +
                      (index === selection ? ' text-white  bg-blue-primary ' : ' bg-gray-200 text-gray-dark')
                    }
                    onClick={() => {
                      setSelection(index);
                      localStorage.setItem('clientid', item.id.toString());
                    }}
                  >
                    <td className="py-1 w-3/12 pl-1">
                      <button
                        className={
                          'rounded-full  py-2 text-sm  text-white w-24 text-center' +
                          (item.status === 'active'
                            ? ' toggle-input-back-ground '
                            : item.status === 'inactive'
                              ? ' stacked-bar-red-primary '
                              : ' stacked-bar-orange-primary ')
                        }
                        onClick={() => {
                          history.push(ROUTE_PATH.REPORTING);
                        }}
                      >
                        {item.status.toUpperCase()}
                      </button>
                    </td>
                    <td
                      className="font-bold font-base cursor-pointer py-1 w-3/12"
                      role="button"
                      onClick={() => {
                        history.push(ROUTE_PATH.GROUPS_CONFIGURE);
                      }}
                    >
                      {item.name}
                    </td>
                    <td className="font-bold font-base py-1 w-3/12">{item.total_eligible_members}</td>
                    <td className="font-bold font-base py-1 w-3/12">{item.total_active_members}</td>
                    <td className="font-bold font-base py-1 w-3/12">{item.reseller?.length ? item.reseller : '-'}</td>
                    <td className="font-bold font-base py-1 w-3/12">
                      {item.contract_date?.length ? item.contract_date : '-'}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {isInvoiceModalOpen && (
        <InvoiceReportModal isOpen={isInvoiceModalOpen} setInvoiceModalOpen={setInvoiceModalOpen} />
      )}
    </div>
  );
};

const InvoiceReportModal: React.FC<{
  isOpen: boolean;
  setInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setInvoiceModalOpen }) => {
  const isMounted = useRef(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { isFetching, refetch } = useInvoiceReport(startDate, endDate, false);

  const handleDownload = () => {
    if (startDate !== '' && endDate !== '') {
      const fetchData = async () => {
        await refetch();
        setInvoiceModalOpen(false);
      };
      fetchData();
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      isModel={isOpen}
      InnerComponent={
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none">
          <div className="relative my-6 mx-auto max-w-5xl min-w-3xl max-h-screen overflow-y-auto">
            <div className="flex flex-col bg-white rounded-2xl border border-black p-8 space-y-8">
              <h2 className="text-violet-950 text-3xl font-bold">Invoicing Run</h2>
              <label className="flex gap-4 justify-center items-center">
                Invoice Start
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </label>
              <label className="flex gap-4 justify-center items-center">
                Invoice End
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </label>
              <div className="flex gap-4">
                <Button variant="primary" className="w-1/2" onClick={handleDownload}>
                  {isFetching ? 'Downloading...' : 'Download Invoice Report'}
                </Button>
                <Button variant="secondary" className="w-1/2" onClick={() => setInvoiceModalOpen(false)}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    // clients: state.Client.clients?.data || null,
    pending: state.Client?.pending || null,
    clientPending: state.Client?.clientPending || null,
  };
};

export default connect(mapStateToProps)(Clients);
