import React, { useState } from 'react';
import { CustomInput, Spinner } from '../common';
import { LOCLogo } from '../../assets/images';
import { Checkbox } from '@kindlyhuman/component-library';
import useAuth from '../../hooks/useAuth';

const LoginDetails: React.FunctionComponent = (): JSX.Element => {
  const [userName, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [trusted, setTrusted] = useState<boolean>(false);
  const [formError, setFormError] = useState<any>();
  const [passwordResetLoading, setPasswordResetLoading] = useState<boolean>(false);

  const { loginClassic, passwordResetClassic } = useAuth();

  const validate = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (userName === '' || userName === undefined || !emailRegex.test(userName)) {
      return setFormError({ userName: 'Enter valid email address' });
    } else if (password === '' || password === undefined || password.length < 8) {
      if (password !== undefined && password === '') {
        return setFormError({ password: 'Enter password' });
      } else if (password !== undefined && password.length < 8) {
        return setFormError({ password: 'Enter min. 8 characters' });
      }
    } else {
      setFormError({});
    }

    loginClassic(userName, password, trusted);
  };

  const handleForgotPassword = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (userName === '' || userName === undefined || !emailRegex.test(userName)) {
      return setFormError({ userName: 'Enter valid email address' });
    }
    setFormError({});
    setPasswordResetLoading(true);
    passwordResetClassic(userName).then(() => {
      setPasswordResetLoading(false);
    });
  };

  return (
    <div
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          validate();
        }
      }}
      tabIndex={0}
    >
      <div className="flex h-screen bg-gray-100">
        {passwordResetLoading ? (
          <Spinner />
        ) : (
          <div className="w-98 m-auto bg-white p-10">
            <div className="flex justify-center items-center">
              <img className="w-4/5" src={LOCLogo} alt="loc-logo" />
            </div>
            <div className="grid grid-cols-1 gap-5 mt-8">
              <CustomInput
                inputType={'text'}
                value={userName}
                setValue={setUsername}
                Label={'Username'}
                Error={formError?.userName}
                ErrorMessage={formError?.userName}
                labelClass="font-normal"
              />
              <CustomInput
                inputType={'password'}
                value={password}
                setValue={setPassword}
                Label={'Password'}
                Error={formError?.password}
                ErrorMessage={formError?.password}
                labelClass="font-normal"
              />
              <div className="flex gap-x-4 pt-4 pl-2">
                <Checkbox variant="DEFAULT" checked={trusted} onChange={() => setTrusted((prev) => !prev)} />
                <div className="text-gray-900 text-base">Trust this device</div>
              </div>
              <button
                type="button"
                className="bg-bright-blue w-full h-11.5 rounded-full text-white font-bold tracking-wide"
                onClick={validate}
              >
                Submit
              </button>
              <div
                className="w-full text-center blue-primary underline font-bold"
                role="button"
                onClick={handleForgotPassword}
              >
                Forgot Password
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginDetails;
