import * as React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AuthenticatedRouter from './authenticated-router';
import { UnauthenticatedRouter } from './unauthenticated-router';
import { Spinner } from '../components/common';

const Routes: React.FunctionComponent = (): JSX.Element => {
  const { isAuthenticated, isLoading, retrieveUserFromQueryParams } = useAuth();

  // This is a style choice. you could just do this is useAuth.
  // I like that all the routing stuff happens here since this is where I'd start looking for something like that
  React.useEffect(() => {
    retrieveUserFromQueryParams();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <Switch>{isAuthenticated ? <AuthenticatedRouter /> : <UnauthenticatedRouter />}</Switch>
    </BrowserRouter>
  );
};
export default Routes;
