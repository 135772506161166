import { AuthProvider } from './contexts/auth-context';
import Routes from './routes';
import './styles/main.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </>
  );
}

export default App;
