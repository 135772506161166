import * as React from 'react';
import LoginDetails from '../../components/account-login';
import useAuth from '../../hooks/useAuth';
import { Spinner } from '../../components/common';

const Login: React.FunctionComponent = (): JSX.Element => {
  const { keycloak, keycloakFeatureFlag, isAuthenticated } = useAuth();

  if (keycloakFeatureFlag && keycloak && !isAuthenticated) {
    keycloak.login();
  }

  if (keycloakFeatureFlag) {
    return <Spinner />;
  }

  return <LoginDetails />;
};
export default Login;
