import { useState } from 'react';
import { useListClients } from '../../hooks/useClients';
import { Experience, useRecommendedExperiencesTextSearch } from '../../hooks/useExperiences';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import SpinnerSVG from '../../assets/images/spinner.svg';
import { Avatar } from '@kindlyhuman/component-library';
import '@kindlyhuman/component-library/dist/style.css';

export const TestSearch = () => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [clientId, setClientId] = useState<number | undefined>(undefined);
  const { data: clients } = useListClients();
  const searchResults = useRecommendedExperiencesTextSearch({
    enabled: !!searchQuery && !!clientId,
    searchFilterOptions: {
      supportQuery: searchQuery,
      limit: 10,
      page: 1,
      required_client_tags_client_id: clientId,
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchQuery(e.currentTarget.search.value.trim());
    queryClient.invalidateQueries(['textSearchExperiences']);
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold mx-8 mt-4">Test Query</h3>
      <form onSubmit={handleSubmit}>
        <select
          value={clientId}
          onChange={(e) => setClientId(Number(e.currentTarget.value))}
          id="client"
          className="border rounded p-2 mx-8 mt-4 w-1/2"
        >
          <option value={undefined}>Select a client</option>
          {clients?.map((client) => (
            <option
              onSelect={(e) => setClientId(Number.parseInt(e.currentTarget.value))}
              key={client.id}
              value={client.id}
            >
              {client.name}
            </option>
          ))}
        </select>
        <input id="search" type="text" placeholder="Search..." className="border rounded p-2 mx-8 mt-4 w-1/2" />
        <button type="submit" className="bg-blue-800 text-white rounded p-2 mx-8 mt-4">
          {searchResults.isLoading && searchQuery ? (
            <img src={SpinnerSVG} alt="spinner" className="animate-spin w-8 h-8" />
          ) : (
            'Submit'
          )}
        </button>
      </form>
      {searchResults.data &&
        searchResults?.data?.data.map((experience) => {
          return <ExperienceCard key={experience.id} experience={experience} />;
        })}
    </div>
  );
};

const ExperienceCard = ({ experience }: { experience: Experience }) => {
  return (
    <div className="py-4 mx-8 gap-4" key={experience.id}>
      <div>
        <div className="border rounded-lg p-4 shadow-md">
          <div className="flex items-center gap-x-4">
            <Avatar className="py-4" image={experience.profile_photo_square_file_url} variant="large" />
            <div>
              <p className="font-semibold text-lg">
                <strong>Name:</strong> {experience.name}
              </p>
              <p>
                <strong>Peer Type:</strong> {experience.peer_type}
              </p>
              <p className="text-gray-600">
                <strong>ID:</strong> {experience.id}
              </p>
            </div>
          </div>
          <div className="space-y-2">
            <h4 className="text-xl font-bold">
              <strong>Title:</strong> {experience.title}
            </h4>
            <p>
              <strong>Summary:</strong> {experience.admin_summary}
            </p>
            <p>
              <strong>Transcription:</strong> {experience.transcription}
            </p>
            <p>
              <strong>Similarity Score:</strong> {experience.similarity_score.toFixed(3)}
            </p>
            <p className="space-x-2">
              <strong>Tags:</strong>
              {experience.tag_names.map((tag) => (
                <span key={tag}>{tag}</span>
              ))}
            </p>
            <p>
              <strong>Audio URL:</strong>{' '}
              <a className="text-blue-400" href={experience.audio_url} target="_blank" rel="noopener noreferrer">
                {experience.audio_url}
              </a>
            </p>
            <p>
              <strong>Duration:</strong> {moment.utc(experience.duration * 1000).format('mm:ss')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
