import resetPassword from "../components/account-login/reset-password";

export const domain = process.env.REACT_APP_DOMAIN_NAME || '';
export const apiVersion = process.env.REACT_APP_API_VERSION || '';
export const apiVersion2 = process.env.REACT_APP_API_VERSION2 || '';

const urls = {
  topics: '/topics/',
  clients: '/clients/',
  login: '/users/authenticate',
  users: '/users/',
  tags: '/tags/',
  tagGroups: '/tag_groups/',
  comms: '/comms',
  reporting: '/reporting/client',
  commsTemplate: '/comms/system_messages',
  listeners: '/listeners',
  campaignTemplate: '/comms/campaign_templates',
  appSettings: '/settings/system/',
  listenerTopics: '/listener_topics/message_quality',
  connections: '/connections/all',
  events: '/events/',
  user: '/users',
  adminDetails: '/client_administrator',
  members: '/members',
  tiers: '/tiers',
  partners: '/partners',
  peers: '/peers',
  resources: '/resources/',
  blackList: '/users/blacklist',
  blocked: '/users/connection_blocks',
  subscriptions: '/subscriptions/',
};

export default urls;
