import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getRollBarEnvironment } from './rollbar';
import { ROUTE_PATH } from './routes/paths';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      onError: (err: any) => {
        if (err.response?.status === 403) {
          localStorage.removeItem('token');
          window.location.href = ROUTE_PATH.LOGIN;
        }
      },
    },
  },
});

const rollbarConfig =
  window.location.origin.indexOf('localhost') !== -1
    ? {}
    : {
        accessToken: process.env.REACT_APP_ROLL_BAR_ACCESS_TOKEN,
        environment: getRollBarEnvironment(),
        code_version: '2.3.0',
      };

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <RollbarProvider config={rollbarConfig}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </RollbarProvider>
        </Provider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
